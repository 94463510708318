import PTheme from './theme';

export const viewStyles = {
    avatar: {
        display: 'inline-block',
        backgroundColor: '#6e6e6e',
        width: 32,
        height: 32,
        textAlign: 'center',
        marginRight: 10,
        paddingTop: 5,
        verticalAlign: 'bottom'
    },
    avatarSvg: {
        display: 'inline-block',
        textAlign: 'center',
        verticalAlign: 'middle'
    },
    break: {display: 'block', width: '100%', height: 0, border: 'none', margin: 0},
    buttonInline: {display: 'inline-block', marginRight: '1em'},
    divider: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#2d5033',
        border: 'none',
        margin: '1em 0 0.5em 0'
    },
    error: {
        color: PTheme.palette.errorColor,
    },
    fieldDescription: {
        flex: '1 1 40%',
        display: 'inline-block',
        width: 'auto',
        height: '2em',
        fontSize: '12px',
        color: 'rgba(33,33,33,0.5)',
        verticalAlign: 'bottom',
    },
    fieldDescriptionTop: {
        display: 'block',
        fontSize: '14px',
        margin: 0,
    },
    fieldTitle: {
        display: 'block',
        width: '100%',
        fontSize: '20px',
        fontWeight: '500',
        color: 'rgba(33,33,33,0.5)',
        margin: '1em 0 1em 0',
    },
    fieldInput: {
        display: 'block',
        width: '100%',
        fontSize: '14px',
        color: 'rgba(33,33,33,0.8)',
        margin: '0 0 2em 0',
    },
    fieldInstructions: {
        display: 'block',
        width: '100%',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'rgba(33,33,33,0.5)',
        margin: '2em 0 2em 0',
    },
    fieldLabel: {
        display: 'block',
        fontSize: '14px',
        color: 'rgba(33,33,33,0.5)',
        margin: '0 0 4px 0',
        verticalAlign: 'bottom',
    },
    fieldLink: {color: '#2d5033', textDecoration: 'none', borderBottom: '1px dotted rgba(33,33,33,0.5)'},
    fieldMultiline: {
        minHeight: 40,
        fontSize: '14px',
        padding: '4px 4px 4px 2px',
    },
    fieldWide: {flex: '0 1 80%', display: 'block', minWidth: '80%', maxWidth: '100%', overflow: 'hidden'},
    fieldWrap: {
        whiteSpace: 'pre-wrap'
    },
    filterDescription: {
        display: 'block',
        width: 'auto',
        height: '2em',
        fontSize: '16px',
        color: 'rgba(33,33,33,0.5)',
        margin: '1em 0 0 16px',
    },
    filterHalf: {minWidth: '50%'},
    filterInline: {display: 'inline-block', fontSize: '16px', marginRight: 10},
    filterLeft: {fontSize: '16px', width: '100%', minWidth: '50%'},
    filterSelectSmall: {
        display: 'inline-block',
        width: 150,
        minWidth: 50,
        maxWidth: 150,
        marginTop: 0,
        marginRight: '1em',
        overflow: 'hidden'
    },
    filterThird: {minWidth: '33%'},
    hidden: {display: 'none'},
    marginBottom: {marginBottom: '1em'},
    marginLeft: {marginLeft: '1em'},
    marginRight: {marginRight: '1em'},
    marginTop: {marginTop: '1em'},
    noMargin: {margin: 0},
    noWrap: {whiteSpace: 'nowrap'},
    noPaddingTop: {paddingTop: 0},
    paddingBottom: {paddingBottom: '1em'},
    rowField: {flex: '0 1 30%', display: 'inline-block', margin: '0 1em 0 0', verticalAlign: 'top'},
    rowShowField: {display: 'inline-block', width: 'auto', minWidth: 100, margin: '0 1em 0 0', verticalAlign: 'top'},
    rowShowFieldWide: {
        display: 'inline-block',
        width: 'auto',
        minWidth: 200,
        margin: '0 1em 0 0',
        verticalAlign: 'top'
    }
};
