import React, {useCallback, useEffect} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import {logOutUser, setKeycloakAuthenticated, setKeycloakReady, setTokens} from '../store/actions';
import Emitter from './eventEmitter';

let tokenTimer;

/**
 * Component for processing Keycloak events and tokens
 *
 * @param keycloak
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const KeycloakHandler = ({keycloak, ...props}) => {

    const setTokenTimer = () => {
        tokenTimer = setInterval(function() {
            let expired = keycloak.isTokenExpired();
            if (keycloak.didInitialize && expired) {
                clearInterval(tokenTimer);
                keycloak.updateToken();
                setTokenTimer();
            }
        }, 5000);
    };

    // Callback: Login User
    const loginUserCallback = useCallback((url) => {
        keycloak.login({
            action: 'login',
            redirectUri: url,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Callback: Update Token
    const updateTokenCallback = useCallback(() => {
        keycloak.updateToken();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Callback: Logout User
    const logoutUserCallback = useCallback(() => {
        localStorage.removeItem('destination');
        props.logOutUser();
        if (keycloak) {
            keycloak.logout();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Effect: Set Keycloak as ready upon event
    useEffect(() => {
        const setKeycloakIsReady = () => {
            props.setKeycloakReady()
        };

        Emitter.on('keycloakReady', setKeycloakIsReady);
        return () => Emitter.off('keycloakReady', setKeycloakIsReady);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Effect: Set Keycloak User as authenticated
    useEffect(() => {
        const setUserAuthenticated = (e) => {
            props.setKeycloakAuthenticated(e);
        };

        Emitter.on('keycloakAuthenticated', (e) => setUserAuthenticated(e));
        return () => Emitter.off('keycloakAuthenticated', (e) => setUserAuthenticated(e));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Effect: Store tokens in Redux when received
    useEffect(() => {
        const storeToken = (props, token) => {
            if (token) {
                // Set Authorization header for all Axios calls
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                props.setTokens({token: token});
            }
        };

        Emitter.on('accessToken', (e) => storeToken(props, e));
        return () => Emitter.off('accessToken', (e) => storeToken(props, e));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Effect: Handle token update prompt
    useEffect(
        () => {
            const handleUpdate = () => {
                updateTokenCallback();
            };

            Emitter.on('updateKeycloak', handleUpdate);
            return () => Emitter.off('updateKeycloak', handleUpdate);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    // Effect: Handle expired token
    useEffect(
        () => {
            const handleLogin = (url) => {
                loginUserCallback(url);
            };

            Emitter.on('loginKeycloak', (e) => handleLogin(e));
            return () => Emitter.off('loginKeycloak', (e) => handleLogin(e));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    // Effect: Handle expired token
    useEffect(
        () => {
            const handleLogout = () => {
                logoutUserCallback();
            };

            Emitter.on('logoutUser', handleLogout);
            return () => Emitter.off('logoutUser', handleLogout);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    // Effect: Handle expired token
    useEffect(
        () => {
            const handleTokenExpired = () => {
                logoutUserCallback();
            };

            Emitter.on('tokenExpired', handleTokenExpired);
            return () => Emitter.off('tokenExpired', handleTokenExpired);

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    if (!tokenTimer) {
        setTokenTimer();
    }

    return (
        <>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        tokens: {
            token: state.tokens.token
        }
    };
};

const mapDispatchToProps = {
    logOutUser: logOutUser,
    setKeycloakAuthenticated: setKeycloakAuthenticated,
    setKeycloakReady: setKeycloakReady,
    setTokens: setTokens
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(KeycloakHandler);
