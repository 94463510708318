import React, {useEffect, useState} from 'react';
import Emitter from '../utils/eventEmitter';
import ProductDetailDialog from '../components/ProductDetailDialog';

/**
 * A component to contain a Product Detail Dialog
 *
 * @return {JSX.Element}
 * @constructor
 */
const ProductDetailDialogContainer = () => {

    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        id: null
    });

    useEffect(
        () => {
            const setProductId = (e) => {
                setItemDetailState({
                    dialog: true,
                    id: e
                });
            };

            // Add a listener to store when Keycloak is ready
            Emitter.on('selectedProductId', (e) => setProductId(e));

            return () => Emitter.off('selectedProductId', (e) => setProductId(e));

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, id: null});
    };

    return (
        <ProductDetailDialog
            openDialog={itemDetailState.dialog}
            productId={(itemDetailState.id) ? itemDetailState.id : null}
            onCloseFunc={handleCloseItemDialog}
        />
    )
}

export default ProductDetailDialogContainer;
