import React from 'react';

const IconSortArrowUp = ({classes = ''}) => {
    return (
        <span className={`icon-sort-arrow up ${classes}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M9 6l-4 4h8z"/>
            </svg>
        </span>
    )
};

export default IconSortArrowUp;
