import * as React from "react";
import {
    List,
    Create,
    Filter,
    SimpleList,
    SimpleShowLayout,
    SimpleForm,
    Toolbar,
    BooleanField,
    TextField,
    SelectInput,
    TextInput,
    SaveButton,
    required
} from 'react-admin';
import Edit from '../components/admin/Edit';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import SelectFromListInput from "../components/SelectFromListInput";
import {CONTACT_COLUMNS} from '../global/listColumns';
import {editButtonColumnObject, showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

export const ContactIcon = PermContactCalendarIcon;

const useToolbarStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between'
    },
});

const validateName = [required()];

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectFromListInput
            inAdminForm={true}
            source="filter_org_id"
            resourceName="Organization"
            listLabel="Select Client"
            itemPlural="Clients"
            returnType="string"
            listFilters={{
                active: props.filterValues.active
            }}
            isRequired={false}
            alwaysOn
            size="small"
            hasClear={false}
            className="input-320"
        />
        <SelectInput
            source="active" label="Active"
            choices={[
                {id: true, name: 'Yes'},
                {id: false, name: 'No'},
            ]}
            alwaysOn
            allowEmpty={false}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Contacts"><ContactIcon style={viewStyles.avatarSvg}/></Avatar>Contacts</span>;

const showButtonColumn = showButtonColumnObject();
const editButtonColumn = editButtonColumnObject();

const listColumns = [...CONTACT_COLUMNS, showButtonColumn, editButtonColumn];

const listSort = {field: 'name', order: 'ASC'};

export const ContactList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true, filter_org_id: ''}}
              bulkActionButtons={false}
              pagination={false}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.city + ', ' + record.state}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable
                    columns={listColumns}
                    sort={listSort}
                    listFilters="filterValues"
                    listFilter="filter_org_id"
                    messageFilter="Select a Client to view Contacts."
                    messageEmpty=" No Contacts found."
                    {...props}
                />
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Contacts"><ContactIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Contact'}</span>;
};

export const ContactShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Contact details below. To edit this Contact, click Edit above.
                `}
            />
            <TextField label="Company" source="name" className="field-bg"/>
            <TextField label="First Name" source="first_name" className="field-bg input-inline half"/>
            <TextField label="Last Name" source="last_name" className="field-bg input-inline half"/>
            <TextField label="Address" source="address" className="field-bg"/>
            <TextField label="Address 2" source="address2" className="field-bg"/>
            <TextField label="City" source="city" className="input-inline input-180"/>
            <TextField label="State" source="state" className="input-inline input-120"/>
            <TextField label="ZIP/Postal Code" source="zip" className="input-inline input-120"/>
            <TextField label="Country" source="country" className="input-inline input-220"/>
            <FieldDivider type="break"/>
            <TextField label="Email Address" source="email" className="field-bg input-inline half"/>
            <TextField label="Phone" source="phone" className="field-bg input-inline half"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);

/**
 * View: Create
 */

const CreateTitle = <span><Avatar icon={<ContactIcon/>} style={viewStyles.avatar}
                                  className="avatar-title"/>Create a Contact</span>;

export const ContactCreate = (props) => (
    <Create title={CreateTitle} {...props}>
        <SimpleForm redirect="list" submitOnEnter={false}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Add a Contact by selecting a Client and entering details below.
                `}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="org_id"
                resourceName="Organization"
                listLabel="Select a Client"
                itemPlural="Clients"
                listFilters={{
                    active: true
                }}
                returnType="string"
                className="input-full"
                isRequired={true}
                hasClear={false}
            />
            <FieldDivider type="break" margin="bottom"/>
            <TextInput label="Company" source="name"
                       helperText="Enter the company name of the Contact. This may be a business name or the full name of a person."
                       fullWidth={true} validate={validateName}/>
            <TextInput label="First Name" source="first_name" formClassName="input-inline half"/>
            <TextInput label="Last Name" source="last_name" formClassName="input-inline half"/>
            <TextInput label="Address" source="address" fullWidth={true}/>
            <TextInput label="Address 2" source="address2" fullWidth={true}/>
            <TextInput label="City" source="city" formClassName="input-inline third"/>
            <TextInput label="State" source="state" formClassName="input-inline third"/>
            <TextInput label="ZIP/Postal Code" source="zip" formClassName="input-inline third"/>
            <TextInput label="Country" source="country" formClassName="input-inline half"/>
            <FieldDivider type="break"/>
            <TextInput label="Email Address" source="email" formClassName="input-inline half"/>
            <TextInput label="Phone" source="phone" formClassName="input-inline half"/>
            <TextInput label="Notes" source="notes" multiline fullWidth={true}/>
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />

            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Contacts List."
            />
        </SimpleForm>
    </Create>
);

/**
 * View: Edit
 */

const EditTitle = ({record}) => {
    return <span><Avatar icon={<ContactIcon/>} style={viewStyles.avatar}
                         className="avatar-title"/>Edit {record ? `${record.name}` : 'Contact'}</span>;
};

const EditToolbar = props => (
    <Toolbar {...props} classes={useToolbarStyles()}>
        <SaveButton/>
    </Toolbar>
);

export const ContactEdit = (props) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<EditToolbar/>}>
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`Edit Contact details below.`}
            />
            <TextInput label="Company" source="name" fullWidth={true} validate={validateName}/>
            <TextInput label="First Name" source="first_name" formClassName="input-inline half"/>
            <TextInput label="Last Name" source="last_name" formClassName="input-inline half"/>
            <TextInput label="Address" source="address" fullWidth={true}/>
            <TextInput label="Address 2" source="address2" fullWidth={true}/>
            <TextInput label="City" source="city" formClassName="input-inline third"/>
            <TextInput label="State" source="state" formClassName="input-inline third"/>
            <TextInput label="ZIP/Postal Code" source="zip" formClassName="input-inline third"/>
            <TextInput label="Country" source="country" formClassName="input-inline half"/>
            <FieldDivider type="break"/>
            <TextInput label="Email Address" source="email" formClassName="input-inline half"/>
            <TextInput label="Phone" source="phone" formClassName="input-inline half"/>
            <TextInput label="Notes" source="notes" multiline fullWidth={true}/>
            <FieldDescription
                description={true}
                text="If this Contact should no longer be referenced, mark it as Inactive."
            />
            <SelectInput source="active" label="Active"
                         choices={[
                             {id: true, name: 'Yes'},
                             {id: false, name: 'No'},
                         ]}
                         allowEmpty={false}
            />
            <FieldDescription
                description={true}
                text="After clicking Save, you will be redirected to the Contacts List."
            />
        </SimpleForm>
    </Edit>
);
