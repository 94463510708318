import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../orderResources';
import {postItem} from '../utils/apiOrderFunctions';
import {getGetHeaders} from '../utils/headers';
import ListLoading from './ListLoading';
import OrderItemsList from './OrderItemsList';

/**
 * A component for retrieving and displaying a Product List by Order ID
 *
 * @param orderId
 * @param hasEditQty
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OrderItemListViewContainer = ({
                                        orderId,
                                        hasEditQty = true,
                                        ...props
                                    }) => {

    const [itemList, setItemList] = useState({
        products: []
    });
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [updateComplete, setUpdateComplete] = useState(false);

    // Effect: Fetch Products by Order ID
    useEffect(
        () => {
            let id = orderId;
            if (!id) {
                id = (props.id) ? props.id : null;
            }
            if (id && !updateComplete) {
                const fetchItems = () => {
                    const params = {
                        active: true,
                        id: id,
                        params: {
                            filter: {},
                            pagination: {page: 1, perPage: 10000},
                            sort: {field: "title", order: "ASC"}
                        }
                    };

                    const cfg = resources.OrderItem.GET_LIST(params);
                    axios({
                        method: 'GET',
                        url: cfg.uri,
                        headers: getGetHeaders(),
                        maxBodyLength: Infinity,
                        maxContentLength: Infinity
                    }).then((response) => {
                        if (response.data && response.data.length > 0) {
                            setItemList(itemList => ({...itemList, products: response.data}));
                            setUpdateComplete(true);
                        }
                        setLoading(false);
                        // resolve(response);
                    }).catch((error) => {
                        console.log('error response for Products: ', error);
                        if (error.response) {
                            // Server response outside 2xx
                        } else if (error.request) {
                            // No response
                        }
                        setLoading(false);
                    });
                };

                // If an Order ID becomes available or has changed, or if a refresh is needed, fetch Products
                if ((orderId || props.id) || !updateComplete) {
                    setLoading(true);
                    fetchItems();
                }
            }

        }, [orderId, props.id, updateComplete]
    );

    const handleUpdateQty = (e) => {
        const item = (e && e.item) ? e.item : null;
        const newQty = (e && e.new_qty) ? e.new_qty : null;
        let id = (props.id) ? parseInt(props.id, 10) : null;
        if (item && newQty && id) {
            // Post the update for the requested Product
            setSaving(true);
            const params = {
                id: item.id,
                order_id: id,
                product_id: item.product_id,
                qty: item.qty,
                new_qty: newQty
            };
            postItem('updateItem', params)
                .then(result => {
                    if (result && result.product_id) {
                        // Display message and reset item list
                        setSaving(false);
                        setSaveError(null);
                        setUpdateComplete(false);
                    }
                })
                .catch(error => {
                    console.log('Product Update Error: ', error);
                    // Display message
                    setSaving(false);
                    setSaveError('Sorry, there was an error updating this Item.');
                });
        }
    };

    return (
        <>
            {loading &&
                <ListLoading text="Loading Products..."/>
            }
            <OrderItemsList
                itemList={itemList.products}
                hasEditQty={hasEditQty}
                hasRemove={false}
                saving={saving}
                error={saveError}
                onEditQtyFunc={(e) => handleUpdateQty(e)}
            />
        </>
    );
}

export default OrderItemListViewContainer;
