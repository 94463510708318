import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {ImageField, ImageInput, useRefresh} from 'react-admin';
import {RESOURCES as resources} from '../productResources';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        fontWeight: 500
    }
});

/**
 * A component to retrieve, display and upload Product Images
 *
 */
const ProductImage = ({addLabel = true, allowUpload = false, label = 'Thumbnail', record = {}, renderType = 'Edit', source = 'thumbnail'}) => {
    const imageType = (record.thumbnail) ? record.thumbnail.substr(record.thumbnail.indexOf('.') + 1) : 'jpg';
    const [state, setState] = useState({
        imageType: imageType,
        newImageFile: null,
        newImageSrc: '',
        thumbnailPath: (record && record.thumbnail) ? record.thumbnail : '',
        thumbnailSrc: ''
    });
    const refresh = useRefresh();

    useEffect(() => {
        const fetchData = () => {
            const params = {
                image_path: record.thumbnail
            };

            const cfg = resources.ProductImage.GET_ONE(params);

            axios({
                method: 'GET',
                url: cfg.uri,
                headers: {
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'image/' + state.imageType
                },
                responseType: 'blob'
            }).then((response) => {
                if (response && response.data) {
                    const src = URL.createObjectURL(response.data);
                    setState({
                        ...state,
                        thumbnailSrc: src
                    });
                }
            }).catch((error) => {
                // console.log('error response for Product Image: ', error);
                if (error.response) {
                    // Server response outside 2xx
                } else if (error.request) {
                    // No response
                }
            });
        };
        if (record && record.thumbnail && (renderType === 'show' || renderType === 'edit')) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteImage = () => {
        const params = {
            product_id: record.id
        };
        const cfg = resources.ProductImage.DELETE(params);

        axios({
            method: cfg.method,
            url: cfg.uri
        }).then((response) => {
            setTimeout(() => {
                refresh();
            }, 1000);
        }).catch((error) => {
            console.log('error response for Product Image: ', error);
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
        });
    };

    const handleDelete = () => {
        deleteImage();
    };

    const {thumbnailSrc} = state;
    const uploadText = (thumbnailSrc && renderType === 'edit') ? 'Replace Product Image' : 'Upload a Product Image';
    const classes = useStyles();

    return (
        <>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription header={true} margin="bottom" text="Product Image"/>
            <FieldDivider type="break" margin="bottom"/>

            <span className="product-image thumbnail">
                    {thumbnailSrc &&
                        <>
                            {(renderType === 'edit') &&
                                <IconButton aria-label="delete" className="delete-icon" onClick={handleDelete}>
                                    <DeleteIcon fontSize="small"/>
                                </IconButton>
                            }
                            <img src={thumbnailSrc} alt="Product"/>
                        </>
                    }
                </span>
            <FieldDivider type="break" margin="bottom"/>

            {allowUpload &&

                <span className="product-image-upload">
                    <ImageInput
                        label={uploadText}
                        source="product_image_input"
                        accept="image/*"
                        multiple={false}
                        minSize={500}
                        maxSize={5000000}
                        placeholder="Drop an Image or click to Browse"
                        fullWidth={false}
                        className={classes.root}
                    >
                        <ImageField source="product_image" label=""/>
                    </ImageInput>
                </span>
            }

            {(renderType === 'create' || renderType === 'edit') &&
                <FieldDivider type="divider" margin="bottom"/>
            }
        </>
    );
};

ProductImage.propTypes = {
    addLabel: PropTypes.bool,
    allowUpload: PropTypes.bool,
    label: PropTypes.string,
    record: PropTypes.object,
    renderType: PropTypes.string,
    source: PropTypes.string
};

export default ProductImage;
