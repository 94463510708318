import React from 'react';

export const fdStyles = {
    field: {
        display: 'block',
        width: 'auto',
        fontSize: '14px',
        color: '#524b50',
        letterSpacing: '0.015em',
        verticalAlign: 'bottom',
        margin: '0 0 1em 0',
    },
    error: {
        color: '#b4212d'
    }
};

/**
 * React-Final-Form capable container for text content
 *
 * @param props
 * @return {*}
 * @constructor
 */
const FieldMessage = (props) => {

    let fieldStyle = fdStyles.field;
    if (props.error) {
        fieldStyle = {...fdStyles.field, ...fdStyles.error};
    }

    return (
        <span style={fieldStyle}>
            {props.text &&
                <>
                    {props.text}
                </>
            }
        </span>
    )
};

export default FieldMessage;
