import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AutocompleteSelect from './AutocompleteSelect';
import {RESOURCES as resources} from '../resources';
import {RESOURCES as orderResources} from '../orderResources';
import {RESOURCES as productResources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import {sortArrayByKey} from '../utils/dataFunctions';

/**
 * An autocomplete component that populates its own choices from an API
 */
class GetListSelectInput extends Component {
    constructor(props) {
        super(props);
        const selectedId = (this.props.selectedId) ? this.props.selectedId : '';
        this.state = {
            selectChoices: [],
            selectedId: selectedId
        };
    }

    componentDidMount() {
        const requiresFilter = this.props.requiresFilter;
        if (this.props.displayField && (!requiresFilter || this.props.filter)) {
            // If no filter is required or a filter is provided, fetch data
            this.fetchData();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.displayField && prevState.selectChoices.length > 0) {
            // If field should not be displayed, clear its state
            return {
                selectChoices: []
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const displayField = this.props.displayField;
        if (displayField && this.props.requiresFilter) {
            if (JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter)) {
                // If a filter is required and has been provided, fetch data
                this.fetchData();
            }
        }
        if (!prevProps.displayField && displayField) {
            // If a cleared field should be displayed, fetch data
            this.fetchData();
        }
    }

    fetchData() {
        let filter = this.props.filter;

        if (this.props.requiresFilter && (!filter || filter === {})) {
            // If a required filter is not provided, do not fetch
            return;
        }

        const params = {
            active: this.props.resourceActive,
            params: {
                filter: filter,
                pagination: {page: 1, perPage: 10000},
                sort: this.props.resourceSort
            }
        };
        const id = this.props.resourceId;
        if (id) {
            params.id = id;
        }

        let resource = resources;
        const resourceFile = this.props.resourceFile;
        if (resourceFile === 'order') {
            resource = orderResources;
        }
        if (resourceFile === 'product') {
            resource = productResources;
        }

        const cfg = resource[this.props.resourceName].GET_LIST(params);

        axios({
            method: 'GET',
            url: cfg.uri,
            headers: getGetHeaders(),
            maxBodyLength: Infinity,
            maxContentLength: Infinity
        }).then((response) => {
            if (response.data && response.data.length > 0) {
                const displayType = this.props.displayType;
                const displayArray = displayType.split(this.props.optionTextSeparator);
                let choices = this.assembleChoiceNames(response.data, displayArray);
                this.setState({
                    selectChoices: choices
                })
            }
            // resolve(response);
        }).catch((error) => {
            console.log('error response for ' + this.props.resourceName + ': ', error);
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
        });
    }

    assembleChoiceNames(choices, displayArray) {
        let assembled = [];
        for (let choice of choices) {

            const separator = this.props.optionTextSeparator;

            // Construct a Display Name of up to four parts based on possible attribute values
            let display_name = choice[displayArray[0]];
            if (displayArray.length > 1) {
                display_name += (choice[displayArray[1]]) ? separator + choice[displayArray[1]] : '';
            }
            if (displayArray.length > 2) {
                display_name += (choice[displayArray[2]]) ? separator + choice[displayArray[2]] : '';
            }
            if (displayArray.length > 3) {
                display_name += (choice[displayArray[3]]) ? separator + choice[displayArray[3]] : '';
            }
            choice.display_name = display_name;
            choice.label = display_name;
            choice.value = choice.id;

            const excluded = this.props.excludedId;
            if (!excluded || excluded !== choice.id) {
                assembled.push(choice);
            }
        }
        const sortObj = this.props.resourceSort;
        const field = (sortObj.field) ? sortObj.field : 'id';
        const order = (sortObj.order) ? sortObj.order : 'ASC'
        if (field) {
            assembled = sortArrayByKey(assembled, field, order);
        }
        return assembled;
    }

    handleOnChange = (e, source) => {
        const value = (e && e.target && e.target.value) ? e.target.value : e;
        const changeFunc = this.props.onChangeFunc;
        if (changeFunc && typeof changeFunc === 'function') {
            changeFunc(value);
        }
    };

    render() {
        const {
            allowEmpty,
            className,
            disabled,
            displayField,
            emptyText,
            isRequired,
            label,
            returnValueType,
            source,
            validate
        } = this.props;

        const {
            selectChoices
        } = this.state;

        return (
            <AutocompleteSelect
                className={(className) ? className : undefined}
                source={source}
                label={label}
                optionText="display_name"
                returnValueType={returnValueType}
                choices={(displayField) ? selectChoices : []}
                allowEmpty={allowEmpty}
                emptyText={emptyText}
                required={isRequired}
                options={{
                    placeholder: label,
                    required: isRequired,
                }}
                disabled={(disabled) ? disabled : undefined}
                validate={(validate) ? validate : undefined}
                onChange={(e) => this.handleOnChange(e, source)}
            />
        );
    }
}

GetListSelectInput.propTypes = {
    addLabel: PropTypes.bool,
    allowEmpty: PropTypes.bool,
    clearOnMount: PropTypes.bool,
    disabled: PropTypes.bool,
    displayField: PropTypes.bool,
    displayType: PropTypes.string,
    emptyText: PropTypes.string,
    excludedId: PropTypes.any,
    filter: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    onChangeFunc: PropTypes.func,
    optionTextSeparator: PropTypes.string,
    requiresFilter: PropTypes.bool,
    resourceActive: PropTypes.bool,
    resourceId: PropTypes.number,
    resourceName: PropTypes.string,
    resourceFile: PropTypes.string,
    resourceSort: PropTypes.object,
    returnValueType: PropTypes.string,
    selectedId: PropTypes.any,
    source: PropTypes.string,
    validate: PropTypes.any
};

GetListSelectInput.defaultProps = {
    addLabel: true,
    allowEmpty: true,
    clearOnMount: false,
    disabled: false,
    displayField: true,
    displayType: 'id - name',
    emptyText: '',
    filter: null,
    isRequired: false,
    optionTextSeparator: ' - ',
    requiresFilter: false,
    resourceActive: true,
    resourceFile: 'default',
    resourceSort: {field: 'id', order: 'ASC'},
    returnValueType: 'value',
    selectedId: '',
    source: "id"
};

export default GetListSelectInput;
