import * as React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import {MenuItemLink} from 'react-admin';
import {logOutUser} from '../store/actions';
import {checkAuthentication} from '../utils/authFunctions';
import {assembleDefaultQueryString} from '../utils/dataFunctions';
import Emitter from '../utils/eventEmitter';
import Logo from '../assets/Parrot-Media-Logo-light.png';
import {useMediaQuery} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Home';
import OrdersIcon from '@material-ui/icons/Receipt';
import {ContactIcon} from '../routes/Contact';
import {ProductIcon} from '../routes/Product';
import {ShowIcon} from '../routes/Show';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PersonIcon from '@material-ui/icons/Person';

const useMenuStyles = makeStyles({
    root: {
        fontSize: '1.25em',
    },
    icon: {
        minWidth: 42,
        '& > svg': {
            fontSize: '1.75em'
        }
    }
});

const inactiveStyle = {
    color: 'rgba(0, 0, 0, 0.54)'
};

const useButtonStyles = makeStyles({
    root: {
        margin: '4px 0 6px 18px',
    }
});

const useIconButtonStyles = makeStyles({
    root: {
        margin: '4px 0 6px 6px',
    }
});

const dividerStyles = {
    collapsed: {
        display: 'none'
    },
    open: {
        margin: '16px 12px 16px 16px',
    },
};

/**
 * Menu with Keycloak Authentication
 *
 * @param onMenuClick
 * @param location
 * @param logout
 * @param keycloakReady
 * @param keycloakAuthenticated
 * @param tokens
 * @return {*}
 * @constructor
 */
const ParrotMenu = ({onMenuClick, location, logout, keycloakAuthenticated, tokens}) => {

    const handleLogout = () => {
        Emitter.emit('logoutUser', true);
    };

    let isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);

    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const classes = useMenuStyles();
    const buttonClasses = useButtonStyles();
    const iconButtonClasses = useIconButtonStyles();
    const dividerClass = (open) ? dividerStyles.open : dividerStyles.collapsed;
    const logoLinkClass = (open) ? 'logo-link' : 'logo-link collapsed';
    let dashboardLinkStyle = (location && location.pathname === '/') ? {} : inactiveStyle;
    const defaultOrderQueryString = assembleDefaultQueryString('Order');

    return (
        <div className="sidebar-menu">
            <Link to="/" title="Dashboard" className={logoLinkClass}>
                <img src={Logo} alt="Parrot Media" className="logo"/>
            </Link>

            {isAuthenticated &&
            <>
                <MenuItemLink
                    to="/"
                    primaryText="Dashboard"
                    leftIcon={<DashboardIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    classes={classes}
                    style={dashboardLinkStyle}
                />

                {/* Products */}
                <MenuItemLink
                    to="/Product"
                    primaryText="Products"
                    leftIcon={<ProductIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    classes={classes}
                />

                {/* Orders */}
                <MenuItemLink
                    to={`/Order${defaultOrderQueryString}`}
                    primaryText="Orders"
                    leftIcon={<OrdersIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    classes={classes}
                />

                {/* Titles (Shows) */}
                <MenuItemLink
                    to="/Show"
                    primaryText="Titles"
                    leftIcon={<ShowIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    classes={classes}
                />

                {/* Contacts */}
                <MenuItemLink
                    to="/Contact"
                    primaryText="Contacts"
                    leftIcon={<ContactIcon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    classes={classes}
                />

                <hr style={dividerClass}/>

                {open && isAuthenticated &&
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PersonIcon/>}
                    onClick={(e) => handleLogout(e)}
                    classes={buttonClasses}
                >
                    Logout
                </Button>
                }
                {!open && isAuthenticated &&
                <IconButton
                    color="primary"
                    onClick={(e) => handleLogout(e)}
                    classes={iconButtonClasses}
                >
                    <PersonIcon/>
                </IconButton>
                }
            </>
            }

            {isXSmall && logout}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {logOutUser}
    )(ParrotMenu)
);
