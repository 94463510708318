import {
    LOG_OUT,
    SET_CURRENT_TABLE_DATA,
    SET_KEYCLOAK_AUTHENTICATED,
    SET_KEYCLOAK_READY,
    SET_ORDERS_RETRIEVED,
    SET_TOKENS,
    SET_URLS
} from './actionTypes';
import {setAuthProviderToken} from '../authProvider';

export const setKeycloakAuthenticated = authenticated => {
    return {
        type: SET_KEYCLOAK_AUTHENTICATED,
        authenticated
    }
};

export const setKeycloakReady = () => {
    return {
        type: SET_KEYCLOAK_READY
    }
};

export const setOrdersRetrieved = () => {
    return {
        type: SET_ORDERS_RETRIEVED
    }
};

export const setCurrentTableData = currentList => {
    return {
        type: SET_CURRENT_TABLE_DATA,
        currentList
    };
};

export const setTokens = tokens => {
    setAuthProviderToken(tokens.token);
    return {
        type: SET_TOKENS,
        tokens
    };
};

export function logOutUser() {
    return {
        type: LOG_OUT
    }
}

export const setUrls = urls => {
    return {
        type: SET_URLS,
        urls
    };
};
