
/**
 * Create POST headers
 *
 * @return Object
 */
export const getAuthHeaders = () => {
    return {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json'
    };
};

/**
 * Create POST headers using access or refresh token
 *
 * @param useBearer
 * @return {{"Cache-Control": string, "Content-Type": string}}
 */
export const getPostHeaders = (useBearer) => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (useBearer === false) {
        headers.Authorization = undefined;
    }
    return headers;
};

/**
 * Create POST headers using access or refresh token
 *
 * @return {{"Cache-Control": string, "Content-Type": string}}
 */
export const getUploadPostHeaders = () => {
    return {
        'Accept': '*/*',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest'
    };
};

/**
 * Create GET headers
 *
 * @return Object
 */
export const getGetHeaders = () => {

    return {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
    };
};

/**
 * Create DELETE headers using access or refresh token
 *
 * @param useBearer
 * @return {{"Cache-Control": string, "Content-Type": string}}
 */
export const getDeleteHeaders = (useBearer) => {
    const headers = {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    if (useBearer === false) {
        headers.Authorization = undefined;
    }
    return headers;
};
