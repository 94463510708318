import React from 'react';
import {Admin, Resource} from 'react-admin';
import {Provider} from 'react-redux';
import axios from 'axios';
import {history} from "./utils/history";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import Emitter from './utils/eventEmitter';
import createPortalStore from './store/store';
import {processKeycloakTokens} from './utils/tokenFunctions';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import customRoutes from './routes';
import englishMessages from './language/en';
import KeycloakHandler from './utils/KeycloakHandler';
import ParrotLayout from './components/Layout';

import {
    ContactList,
    ContactShow,
    ContactCreate,
    ContactEdit,
    ContactIcon
} from './routes/Contact';
import {
    OrderList,
    OrderShow,
    OrderIcon
} from './routes/Order';
import {
    OrganizationShow,
    OrganizationIcon
} from './routes/Organization';
import {
    ProductList,
    ProductShow,
    ProductIcon
} from './routes/Product';
import {
    ShowList,
    ShowShow,
    ShowIcon
} from './routes/Show';
import Dashboard from './routes/Dashboard';
import LoginPage from './routes/Login';
import NotFound from './routes/NotFound';

import PTheme from './global/theme';
import {createTheme} from '@material-ui/core/styles';

const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {allowMissing: true});
const theme = createTheme(PTheme);

/**
 * Initialize Keycloak
 *
 * @param keycloak
 * @param config
 * @returns {Promise<void>}
 */
const initKeycloak = async (keycloak, config) => {
    try {
        await keycloak.init(config).then(function (result) {
            if (keycloak.didInitialize) {
                Emitter.emit('keycloakReady', true);
            }
            Emitter.emit('keycloakAuthenticated', keycloak.authenticated);
            if (keycloak.authenticated === false) {
                Emitter.emit('loginKeycloak', window.origin);
            } else {
                processKeycloakTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
            }
        }).catch(function (e) {
            console.log('Keycloak initialization error: ', e);
        });
    } catch (error) {
        console.log('Error: Authentication failed: ', error);
    }
};

/**
 * Set listeners for the Keycloak instance
 *
 * @param keycloak
 */
const setKeycloakEventHandlers = (keycloak) => {
    keycloak.onAuthLogout = () => {
        processKeycloakTokens(null, null, null);
    };
    keycloak.onAuthRefreshSuccess = () => {
        processKeycloakTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
        // Set Authorization header for all Axios calls
        axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
    };
    keycloak.onAuthSuccess = () => {
        processKeycloakTokens(keycloak.token, keycloak.idToken, keycloak.refreshToken);
        // Set Authorization header for all Axios calls
        axios.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`;
    };
    keycloak.onReady = () => {
    };
    keycloak.onTokenExpired = () => {
        processKeycloakTokens(null, null, null);
    };
};

const App = ({
                 configParams,
                 keycloak,
                 keycloakInitConfig
             }) => {

    const dp = Object.assign(dataProvider);
    const store = createPortalStore({authProvider, dp, history});
    initKeycloak(keycloak, keycloakInitConfig).then(() => {
        setKeycloakEventHandlers(keycloak);
    });

    return (
        <Provider
            store={store}
        >
                <>
                    <Admin
                        theme={theme}
                        authProvider={authProvider}
                        configParams={configParams}
                        dataProvider={dp}
                        history={history}
                        i18nProvider={i18nProvider}
                        title="Parrot Media Portal"
                        layout={ParrotLayout}
                        dashboard={Dashboard}
                        customRoutes={customRoutes}
                        loginPage={LoginPage}
                        catchAll={NotFound}
                        disableTelemetry
                    >
                        <Resource
                            name="Contact" icon={ContactIcon}
                            list={ContactList} show={ContactShow}
                            create={ContactCreate} edit={ContactEdit}
                            options={{label: 'Contacts'}}
                        />
                        <Resource
                            name="Order" icon={OrderIcon}
                            list={OrderList} show={OrderShow}
                            options={{label: 'Orders'}}
                        />
                        <Resource
                            name="Organization" icon={OrganizationIcon}
                            show={OrganizationShow}
                            options={{label: 'Clients'}}
                        />
                        <Resource
                            name="Product" icon={ProductIcon}
                            list={ProductList} show={ProductShow}
                            options={{label: 'Products'}}
                        />
                        <Resource
                            name="Show" icon={ShowIcon}
                            list={ShowList} show={ShowShow}
                            options={{label: 'Titles'}}
                        />
                        <Resource name="ClientUserOrg"/>
                        <Resource name="Org"/>
                        <Resource name="OrdersByStatus"/>
                        <Resource name="Products"/>
                        <Resource name="ProductsByOrg"/>
                        <Resource name="ProductsByShow"/>
                        <Resource name="ProductsByTitle"/>
                        <Resource name="ProductsByType"/>
                        <Resource name="ProductType"/>
                        <Resource name="ShippingCompany"/>
                        <Resource name="ShippingMethod"/>
                    </Admin>
                    <KeycloakHandler keycloak={keycloak}/>
                </>
        </Provider>
    );
};

export default App;
