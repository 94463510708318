import * as React from "react";
import {Route} from 'react-router-dom';

import Dashboard from './routes/Dashboard';
import LoginPage from './routes/Login';
import OrderCreate from './routes/OrderCreate';

const customRoutes = [
    <Route exact path="/Order/create" component={OrderCreate}/>,
    <Route path="/login&state=:query" component={LoginPage}/>,
    <Route path="/&error=login_required&state=:query" component={LoginPage}/>,
    <Route path="/&state=:query" component={Dashboard}/>,
    <Route path="/state=:query" component={Dashboard}/>,
];

export default customRoutes;