import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';

/**
 * A component to retrieve and display a Product Thumbnail for a list
 *
 */
const ProductThumbnail = ({id = '', title = '', thumbnail = ''}) => {

    const mounted = useRef(false);
    const [productId, setProductId] = useState(id);
    const [productThumbnail, setProductThumbnail] = useState('');
    const [src, setSrc] = useState('');

    const fetchData = useCallback((path) => {
        if (path && path !== '') {
            const imageType = (path) ? path.substring(path.indexOf('.') + 1) : 'jpg';
            const thumbPath = path.replace('/assets', '/thumbnail');
            const cfg = resources.ProductImageThumbnail.GET_ONE({image_path: thumbPath});
            axios({
                method: 'GET',
                url: cfg.uri,
                headers: {
                    'Content-Type': 'image/' + imageType
                },
                responseType: 'blob'
            }).then((response) => {
                if (response && response.data) {
                    let obj = URL.createObjectURL(response.data);
                    if (mounted.current === true) {
                        setSrc(obj);
                    }
                }
            }).catch((error) => {
                if (error.response) {
                    // Server response outside 2xx
                } else if (error.request) {
                    // No response
                }
            });
        }
    }, []);

    useEffect(() => {
        mounted.current = true;

        if (id !== productId || thumbnail !== productThumbnail) {
            setProductId(id);
            setProductThumbnail(thumbnail);
            setSrc('');
            fetchData(thumbnail);
        }
        return () => {
            mounted.current = false;
        };

    }, [id, productId, src, thumbnail, productThumbnail, fetchData]);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <span className="product-image list-thumbnail">
            {src !== '' &&
                <>
                    <img src={src} alt={title}/>
                    <span className="enlarge">
                        <img src={src} alt={title}/>
                    </span>
                </>
            }
        </span>
    );
};

export default ProductThumbnail;
