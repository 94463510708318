import {
    isBlank
} from './utils/dataFunctions';

/**
 * Intercept resources that attempt to load data before a filter value is available
 *
 * @param resource
 * @param type
 * @param params
 *
 * @return boolean
 */
export const interceptLoadData = (type, resource, params) => {
    const filter = params.filter;
    if (resource === 'Contact' && type === 'GET_LIST') {
        if (isBlank(filter.filter_org_id)) {
            return true;
        }
    }
    if (resource === 'Order' && type === 'GET_LIST') {
        const filterOrdersBy = filter.filter_by;
        if (
            isBlank(filterOrdersBy) ||
            (filterOrdersBy === 'client' && isBlank(filter.filter_org_id)) ||
            (filterOrdersBy === 'contact' && (isBlank(filter.filter_org_id) || isBlank(filter.filter_contact_id))) ||
            (filterOrdersBy === 'date_range' && (isBlank(filter.filter_from) || isBlank(filter.filter_to))) ||
            (filterOrdersBy === 'id' && (isBlank(filter.filter_id) || filter.filter_id.length < 3 )) ||
            (filterOrdersBy === 'sku' && (isBlank(filter.filter_sku) || filter.filter_sku.length < 12 )) ||
            (filterOrdersBy === 'status' && isBlank(filter.filter_status_id) && (isBlank(filter.filter_from) || isBlank(filter.filter_to)))
        ) {
            return true;
        }
    }
    if (resource === 'Product' && type === 'GET_LIST') {
        if (
            (!isBlank(filter.filter_title) && filter.filter_title.length > 2)
        ) {
            // Allow title search
            return false;
        }
        if (!isBlank(filter.filter_sku)) {
            let sku = filter.filter_sku.toLowerCase().replace('sku-n', '').replace('sku-', '');
            if (sku.length < 6) {
                return true;
            } else {
                return false;
            }
        }
        const filterProductBy = filter.filter_by;
        if (
            isBlank(filterProductBy) ||
            (filterProductBy === 'client' && isBlank(filter.filter_org_id)) ||
            (filterProductBy === 'product_type' && isBlank(filter.filter_product_type_id)) ||
            (filterProductBy === 'show' && isBlank(filter.filter_show_id))
        ) {
            return true;
        }
    }
    if (resource === 'ShippingMethod' && type === 'GET_LIST') {
        if (isBlank(filter.filter_company_id)) {
            return true;
        }
    }
    return false;
};

/**
 * Modify format of params before a GET call
 *
 * @param type
 * @param resource
 * @param params
 * @return {{active: *, id: *, params: *}}
 */
export const formatGetParams = (type, resource, params) => {
    if (type === 'GET_LIST') {
        if (
            resource === 'Contact' ||
            resource === 'Order' ||
            resource === 'Organization' ||
            resource === 'Show' ||
            resource === 'ShippingCompany' ||
            resource === 'ShippingMethod' ||
            resource === 'ParentProductType' ||
            resource === 'ProductType' ||
            resource === 'Product'
        ) {
            params = preProcessActiveIdParams(params);
        }
    }
    if (
        (resource === 'ProductsByOrg') ||
        (resource === 'ProductsByShow') ||
        (resource === 'ProductsByTitle') ||
        (resource === 'ProductsByType')
    ) {
        params = preProcessActiveIdParams(params);
    }
    return params;
};

/**
 * Format parameters and query string before a GET call
 *
 * @param params
 * @return {{active: *, id: *, params: *}}
 */
const preProcessActiveIdParams = (params) => {
    let active = params.filter.active;
    let id = params.filter.id;
    delete params.filter.active;
    delete params.filter.id;

    return {
        active: active,
        id: id,
        params: params
    };
};

/**
 * Modify or format data before a POST call
 *
 * @param type
 * @param resource
 * @param data
 * @return {*}
 */
export const formatPostData = (type, resource, data) => {
    if (resource === 'Contact') {
        data = formatPostContactData(type, data);
    }
    if (resource === 'Show') {
        data = formatPostShowData(type, data);
    }
    return data;
};

/* Processing for Specific Resources ::::: */

const formatPostContactData = (type, data) => {
    delete data.modified;
    delete data.notes;

    return data;
};

const formatPostShowData = (type, data) => {
    if (data.id) {
        data.id = parseInt(data.id, 10);
    }
    if (data.new_org_id) {
        data.org_id = parseInt(data.new_org_id, 10);
    }
    delete data.current_org_id;
    delete data.new_org_id;

    return data;
};
