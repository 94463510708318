import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import ProductDetailDialog from '../components/ProductDetailDialog';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles({
    btn: {
        marginTop: -4,
        marginRight: 4,
        padding: 2
    },
    btnInfo: {
        position: 'absolute',
        top: 2,
        right: 4,
        padding: 2
    },
    btnText: {
        marginRight: 6,
        padding: '0px 10px'
    },
    message: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.54)'
    },
    messageError: {
        fontSize: 16,
        color: '#b4212d'
    },
    saving: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    table: {},
    tHead: {},
    tBody: {},
    tr: {},
    th: {
        lineHeight: '1.2',
        verticalAlign: 'bottom'
    },
    td: {
        position: 'relative',
        padding: '5px 16px 5px 16px'
    },
    tdName: {
        position: 'relative',
        padding: '5px 36px 5px 16px'
    }
});

/**
 * A component to display a list of Products for an Order
 *
 * @param onEditQtyFunc
 * @param onRemoveFunc
 * @param itemList
 * @param hasEditQty
 * @param hasRemove
 * @param saving
 * @param error
 * @return {JSX.Element}
 * @constructor
 */
const OrderItemsList = ({
                            onEditQtyFunc,
                            onRemoveFunc,
                            itemList = [],
                            hasEditQty = false,
                            hasRemove = true,
                            saving = false,
                            error = null
                        }) => {

    const [currentItem, setCurrentItem] = useState(null);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [qty, setQty] = useState(-1);
    const [availableQty, setAvailableQty] = useState(0);
    const [newQty, setNewQty] = useState(-1);
    const [saveRequest, setSaveRequest] = useState(false);
    const [saveMessage, setSaveMessage] = useState({
        error: false,
        message: ''
    });
    const [itemDetailState, setItemDetailState] = useState({
        dialog: false,
        item: null
    });

    // Effect: Fetch Product by ID
    useEffect(
        () => {
            if (currentItem && currentItem.product_id) {
                const fetchItem = () => {
                    const cfg = resources.Product.GET_ONE({id: currentItem.product_id});
                    axios({
                        method: 'GET',
                        url: cfg.uri,
                        headers: getGetHeaders()
                    }).then((response) => {
                        if (response.data && response.data.qty) {
                            // Set the maximum Quantity to request for this Product
                            setAvailableQty(response.data.qty);
                        }
                    }).catch((error) => {
                        console.log('error response for Product: ', error);
                        if (error.response) {
                            // Server response outside 2xx
                        } else if (error.request) {
                            // No response
                        }
                    });
                };

                // If current Item ID is set or has changed, fetch that Product
                if (currentItem && currentItem.product_id) {
                    fetchItem();
                }
            }

        }, [currentItem, qty]
    );

    // Effect: Reset items after an item has been updated
    useEffect(
        () => {
            if (currentItem && saveRequest && !saving) {
                handleEditQtyClose();
                setSaveMessage({error: false, message: (error) ? error : 'Item saved.'})
            }
        }, [currentItem, saveRequest, saving, error]
    );

    // All Views: Handle event from Info button
    const handleInfoClick = (item) => {
        setItemDetailState({dialog: !!(item), item: item});
    };

    const handleCloseItemDialog = () => {
        setItemDetailState({dialog: false, item: null});
    };

    // Create View: Handle event from Remove Item button
    const handleRemoveClick = (id) => {
        if (typeof onRemoveFunc === 'function') {
            let idx = itemList.findIndex(r => r.product_id === id);
            if (idx !== -1) {
                itemList[idx].qty_requested = 0;
                onRemoveFunc({item: itemList[idx], add: false, updateAvailable: true});
            }
        }
    };

    // Edit View: Handle event from Edit Qty button
    const handleEditQtyClick = (id) => {
        let idx = itemList.findIndex(r => r.product_id === id);
        if (idx !== -1) {
            const qty = itemList[idx].qty;
            // Display the New Qty input
            setQty(qty);
            setAvailableQty(qty);
            setNewQty(qty);
            setCurrentItem(itemList[idx]);
            setCurrentItemId(id);
            setSaveMessage({error: false, message: ''});
        }
    };

    // Edit View: Handle event from change in New Qty field
    const handleEditQtyChange = (e, id) => {
        const value = (e.target && e.target.value) ? e.target.value : null;
        if (value) {
            let qty = parseInt(value, 10);
            if (qty > availableQty) {
                qty = availableQty;
            }
            setNewQty(qty);
        }
    };

    // Edit View: Handle event from Qty Save button
    const handleSaveEditQty = (id) => {
        if (typeof onEditQtyFunc === 'function') {
            setSaveRequest(true);
            currentItem.qty_requested = newQty;
            onEditQtyFunc({item: currentItem, new_qty: newQty, add: true, updateAvailable: true});
        }
    };

    // Edit View: Handle event from Edit Qty Cancel button
    const handleEditQtyClose = () => {
        setQty(-1);
        setAvailableQty(0);
        setNewQty(-1);
        setCurrentItem(null);
        setCurrentItemId(null);
        setSaveRequest(false);
    };

    // Generate Order Item list rows
    const setRows = () => {
        const list = itemList;
        if (!list || list.length === 0) {
            return (
                <span key="file-empty">No Items Added</span>
            )
        }

        return (
            itemList.map((item, index) => (
                <TableRow className={classes.tr} key={`order-item-${item.title}-${index}`}>

                    <TableCell className={classes.td}>{item.sku}</TableCell>

                    <TableCell className={classes.td}>{item.show_name}</TableCell>

                    <TableCell className={classes.td}>{item.type_name}</TableCell>

                    <TableCell className={classes.tdName}>
                        {item.title} &nbsp;
                        <IconButton
                            aria-label="Info"
                            className={classes.btnInfo}
                            onClick={() => handleInfoClick(item)}
                        >
                            <InfoIcon/>
                        </IconButton>
                    </TableCell>

                    <TableCell align="right" className={classes.td}>{item.qty}</TableCell>

                    {hasEditQty &&
                        <TableCell className={classes.td}>
                            {(!currentItemId || currentItemId !== item.product_id) &&
                                <IconButton
                                    aria-label="Edit Qty"
                                    className={classes.btn}
                                    onClick={() => handleEditQtyClick(item.product_id)}
                                    disabled={!!(currentItemId && currentItemId !== item.product_id)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            }
                            {currentItemId && currentItemId === item.product_id &&
                                <>
                                    {!saving &&
                                        <>
                                            <TextField
                                                type="number"
                                                variant="outlined"
                                                id={`edit-order-item-qty-${index}`}
                                                label=""
                                                defaultValue={newQty}
                                                className="input-table-small number"
                                                onChange={(e) => handleEditQtyChange(e, item.product_id)}
                                                InputProps={{
                                                    inputProps: {
                                                        max: availableQty,
                                                        min: 0,
                                                        step: 1
                                                    }
                                                }}
                                            />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.btnText}
                                                startIcon={<SaveIcon/>}
                                                onClick={() => handleSaveEditQty(item.product_id)}
                                                disabled={(qty === newQty)}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                className={classes.btnText}
                                                startIcon={<CloseIcon/>}
                                                onClick={() => handleEditQtyClose()}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                    }
                                    {saving &&
                                        <span className={classes.saving}>Saving...</span>
                                    }
                                </>
                            }
                        </TableCell>
                    }

                    {hasRemove &&
                        <TableCell className={classes.td}>
                            <IconButton
                                aria-label="Remove"
                                className={classes.btn}
                                onClick={() => handleRemoveClick(item.product_id)}
                            >
                                <RemoveCircleOutlineIcon/>
                            </IconButton>
                        </TableCell>
                    }
                </TableRow>
            ))
        )
    };

    const classes = useStyles();

    return (
        <>
            {itemList.length > 0 &&
                <div className="order-item-list-container">
                    <Table className={classes.table} size="small" stickyHeader aria-label="Added Items">
                        <TableHead className={classes.tHead}>
                            <TableRow>
                                <TableCell className={classes.th}>SKU</TableCell>
                                <TableCell className={classes.th}>Title</TableCell>
                                <TableCell className={classes.th}>Category</TableCell>
                                <TableCell className={classes.th}>Product</TableCell>
                                <TableCell align="right" className={classes.th}>Qty<br/>Requested</TableCell>
                                {hasEditQty &&
                                    <TableCell className={classes.th}>Edit Qty</TableCell>
                                }
                                {hasRemove &&
                                    <TableCell className={classes.th}>Remove</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.tBody}>
                            {[...setRows()]}

                            {saveMessage.message !== '' &&
                                <TableRow key="order-item-message">
                                    <TableCell colSpan={5} align="right">
                            <span className={(saveMessage.error) ? classes.messageError : classes.message}>
                                {saveMessage.message}
                            </span>
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <ProductDetailDialog
                        openDialog={itemDetailState.dialog}
                        productId={(itemDetailState.item) ? itemDetailState.item.product_id : null}
                        onCloseFunc={handleCloseItemDialog}
                    />
                </div>
            }
        </>
    )
}

export default OrderItemsList;
