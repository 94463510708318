/**
 * Product Resources for API
 *      Product List by Filter
 *      Product by ID, SKU
 *      Product Image
 *      Products by Column
 */

export const RESOURCES = {
    'Product': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            const filter = params.params.filter;
            const filterBy = params.params.filter.filter_by;
            let uri;
            if (filter.filter_sku) {
                // Search Product by search term (SKU)
                uri = '/api/v1/mgmt/search/productsBySku/' + encodeURI(filter.filter_sku);
            } else if (filter.filter_title) {
                // Search Products by search term (Title)
                uri = '/api/v1/mgmt/productsByTitle/' + encodeURI(filter.filter_title) + '/' + active;
            } else if (filterBy === 'client' && filter.filter_org_id) {
                // Get Products by selected Client (Organization) ID
                uri = '/api/v1/mgmt/productsByOrg/' + filter.filter_org_id + '/' + active;
            } else if (filterBy === 'show' && filter.filter_show_id) {
                // Get Products by selected Title (Show) ID
                uri = '/api/v1/mgmt/productsByShow/' + filter.filter_show_id + '/' + active;
            } else if (filterBy === 'product_type' && filter.filter_product_type_id) {
                // Get Products by selected Product Category (Type) ID
                uri = '/api/v1/mgmt/productsByType/' + filter.filter_product_type_id + '/' + active;
            } else if (filterBy === 'product_type' && filter.filter_parent_product_type_id) {
                // Get Products by selected parent Product Category (Type) ID
                uri = '/api/v1/mgmt/productsByType/' + filter.filter_parent_product_type_id + '/' + active;
            } else {
                uri = '/api/v1/mgmt/products/' + active;
            }
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + uri
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/product/id/' + params.id
            }
        }
    },
    'Products': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/products/' + active
            }
        }
    },
    'ProductById': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/product/id/' + params.id
            }
        }
    },
    'ProductBySku': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/product/sku/' + params.sku
            }
        }
    },
    'ProductImage': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt' + params.image_path
            }
        }
    },
    'ProductImageThumbnail': {
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt' + params.image_path
            }
        }
    },
    'ProductsByOrg': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productsByOrg/' + id + '/' + active
            }
        }
    },
    'ProductsByShow': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productsByShow/' + id + '/' + active
            }
        }
    },
    'ProductsByTitle': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productsByTitle/' + active
            }
        }
    },
    'ProductsByType': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Products',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productsByType/' + id + '/' + active
            }
        }
    }
};

export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "ProductsByType"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}
