import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {routerMiddleware, connectRouter} from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import {all, fork} from 'redux-saga/effects';
import thunk from 'redux-thunk';
import {
    adminReducer,
    adminSaga,
    USER_LOGOUT
} from 'react-admin';
import keycloakReducer from './keycloakReducer';
import notificationReducer from './notificationReducer';
import currentTableDataReducer from './currentTableDataReducer';
import tokensReducer from './tokensReducer';
import urlsReducer from './urlsReducer';
import Config from '../config';

const initialState = {
    currentTableData: Config.currentTableData,
    keycloakState: Config.keycloakState,
    tokens: Config.tokensDefault,
    urls: Config.urls
};

const createAdminStore = ({
                              authProvider,
                              dataProvider,
                              history
                          }) => {

    // Combine all reducers (React-Admin, Parrot)
    const reducer = combineReducers({
        admin: adminReducer,
        router: connectRouter(history),
        tokens: tokensReducer,
        keycloakState: keycloakReducer,
        ordersRetrieved: notificationReducer,
        urls: urlsReducer,
        currentTableData: currentTableDataReducer
    });
    const resettableAppReducer = (state, action) =>
        reducer(action.type !== USER_LOGOUT ? state : undefined, action);

    // Combine sagas (React-Admin)
    const saga = function* rootSaga() {
        yield all(
            [
                adminSaga(dataProvider, authProvider),
                // Add sagas
            ].map(fork)
        );
    };
    const sagaMiddleware = createSagaMiddleware();

    // Add enhancers for Redux
    const composeEnhancers =
        (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
        })) ||
        compose;

    const store = createStore(
        resettableAppReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                sagaMiddleware,
                routerMiddleware(history),
                thunk
            )
        ),
    );
    sagaMiddleware.run(saga);

    return store;
};

export default createAdminStore;
