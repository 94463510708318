import * as React from "react";
import {
    SimpleShowLayout,
    BooleanField,
    TextField
} from 'react-admin';
import Show from '../components/admin/Show';
import FieldDescription from '../components/FieldDescription';
import {viewStyles} from '../global/styles';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';

export const OrganizationIcon = BusinessIcon;

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Clients"><OrganizationIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Client'}</span>;
};

export const OrganizationShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Client details below. To edit this Client, click Edit above.
                `}
            />
            <TextField label="ID" source="id" className="input-inline input-120"/>
            <TextField label="Group Name" source="machine_name" className="input-inline input-280"/>
            <TextField label="Name of Client" source="name" className="field-bg"/>
            <TextField label="Email Address" source="email" className="field-bg"/>
            <TextField label="Phone" source="phone" className="field-bg"/>
            <TextField label="Address" source="address" className="field-bg"/>
            <TextField label="City" source="city" className="input-inline input-180"/>
            <TextField label="State" source="state" className="input-inline input-120"/>
            <TextField label="Zip" source="zip" className="input-inline input-120"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);