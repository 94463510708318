import {setItemDateValues, stripWhiteSpace} from './utils/dataFunctions';
import {RESOURCES as resources} from './productResources';

/**
 * Pre-process incoming single item data consisting of an array
 *
 * @param resource
 * @param type
 * @param params
 * @param rdata
 * @returns {{location: null, locations: *[], id: null}}
 */
export const preProcessSingleListData = (resource, type, params, rdata) => {
    return rdata;
};

/**
 * Post-Process incoming list or item data
 *
 * @param resource
 * @param type
 * @param params
 * @param rdata
 *
 * @return {*}
 */
export const postProcessData = (resource, type, params, rdata) => {

    if (resource === 'Contact') {
        if (type === 'GET_LIST') {
            rdata = processGetContactsData(rdata);
        }
        if (type === 'GET_ONE') {
            rdata = processGetContactData(rdata);
        }
    }
    if (resource === 'Order') {
        if (type === 'GET_LIST') {
            rdata = processGetOrdersData(rdata);
        }
        if (type === 'GET_ONE') {
            rdata = processGetOrderData(rdata);
        }
    }
    if (resource === 'Product') {
        if (type === 'GET_LIST') {
            rdata = processGetProductsData(rdata);
        }
        if (type === 'GET_ONE') {
            rdata = processGetProductData(rdata);
        }
    }
    if (resource === 'Show' && type === 'GET_ONE') {
        rdata = processGetShowData(rdata);
    }
    return rdata;
};

/* ID and Key exported functions ::::: */

/**
 * Mark resources without ids or with different unique identifiers
 */
export const setUniqueKeys = (resource) => {
    const parseParams = {
        hasNameKeys: false,
        needsId: false,
        parentKey: null,
        idKey: null,
    };
    if (resource === 'ProductBySku') {
        parseParams.idKey = 'sku';
    }
    return parseParams;
};

/**
 * Convert GET_ALL responses that are formatted as an object with totals
 */
export const getListFromDataObject = (resource, type, rdata) => {
    if (resource === 'Order' && type === 'GET_LIST') {
        return {
            list: (rdata.orders && rdata.orders.length > 0) ? rdata.orders : [],
            total: rdata.total
        }
    }
    return {
        list: rdata,
        total: rdata.length
    }
};

/* Processing for Specific Resources ::::: */

const processGetContactsData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    // Remove extra spaces from Contact fields
    rdata.data.forEach(function (item, idx) {
        item.name = stripWhiteSpace(item.name);
        item.first_name = stripWhiteSpace(item.first_name);
        item.last_name = stripWhiteSpace(item.last_name);
        item.city = stripWhiteSpace(item.city);
        if (item.name === '') {
            item.name = item.first_name + ' ' + item.last_name;
        }
        if (item.notes) {
            delete item.notes;
        }
    });
    return rdata;
};

const processGetContactData = (rdata) => {
    if (rdata.data.notes) {
        delete rdata.data.notes;
    }
    return rdata;
};

const processGetOrdersData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    // Convert Modified Date values to readable string
    rdata.data.forEach(function (item, idx) {
        item.ship_to_name = (item.ship_to.name) ? item.ship_to.name : '';

        item.created_on_date = '';
        item.created_on_string = '';
        let createdOn;
        if (item.created_on) {
            createdOn = setItemDateValues(item.created_on);
            item.created_on_date = createdOn.date_item;
            item.created_on_string = createdOn.date_string;
        } else if (item.modified) {
            createdOn = setItemDateValues(item.modified);
            item.created_on_date = createdOn.date_item;
            item.created_on_string = createdOn.date_string;
        }
        if (item.modified) {
            const modifiedDate = setItemDateValues(item.modified);
            item.modified_date = modifiedDate.date_item;
            item.modified_string = modifiedDate.date_string;
        }
        item.required_by_date = '';
        item.required_by_string = '';
        if (item.required_by) {
            const requiredByDate = setItemDateValues(item.required_by);
            item.required_by_date = requiredByDate.date_item;
            item.required_by_string = requiredByDate.date_string;
        }
        item.shipped_on_date = '';
        item.shipped_on_string = '';
        if (item.shipped_on) {
            const shippedOnDate = setItemDateValues(item.shipped_on);
            item.shipped_on_date = shippedOnDate.date_item;
            item.shipped_on_string = shippedOnDate.date_string;
        }
    });
    return rdata;
};

const processGetOrderData = (rdata) => {
    // Convert Modified Date values to readable string
    rdata.data.created_on_date = '';
    rdata.data.created_on_string = '';
    if (rdata.data.created_on) {
        const createdOn = setItemDateValues(rdata.data.created_on);
        rdata.data.created_on_date = createdOn.date_item;
        rdata.data.created_on_string = createdOn.date_string;
    }
    if (rdata.data.modified) {
        const modifiedDate = setItemDateValues(rdata.data.modified);
        rdata.data.modified_date = modifiedDate.date_item;
        rdata.data.modified_string = modifiedDate.date_string;
    }
    rdata.data.required_by_date = '';
    rdata.data.required_by_string = '';
    if (rdata.data.required_by) {
        const requiredByDate = setItemDateValues(rdata.data.required_by);
        rdata.data.required_by_date = requiredByDate.date_item;
        rdata.data.required_by_string = requiredByDate.date_string;
    }
    rdata.data.shipped_on_date = '';
    rdata.data.shipped_on_string = '';
    if (rdata.data.shipped_on) {
        const shippedOnDate = setItemDateValues(rdata.data.shipped_on);
        rdata.data.shipped_on_date = shippedOnDate.date_item;
        rdata.data.shipped_on_string = shippedOnDate.date_string;
    }

    return rdata;
};

const processGetProductsData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    let ids = [];
    let data = [];
    rdata.data.forEach(function (item, idx) {
        // Portal excludes 0-qty Products from visual display
        if (item.qty > 0) {
            // Add Display Categories
            let type_display_name = item?.type_name ?? '';
            if (item.parent_type_name && item.type_name) {
                type_display_name = item.parent_type_name + ' > ' + type_display_name;
            }
            item.type_display_name = type_display_name;

            // Get Image path

            const imageBase = resources.ProductImage.GET_ONE({image_path: item.thumbnail});
            item.image_path = (item.thumbnail && item.thumbnail !== '') ? imageBase.uri : null;

            // Convert date values to readable strings
            if (item.modified) {
                const modifiedDate = setItemDateValues(item.modified);
                item.modified_date = modifiedDate.date_item;
                item.modified_string = modifiedDate.date_string;
            }
            delete item.warehouse_locations;

            ids.push(item.id);
            data.push(item);
        }
    });
    rdata.ids = [...ids];
    rdata.data = [...data];
    rdata.total = data.length;
    return rdata;
};

const processGetProductData = (rdata) => {
    let type_display_name = rdata.data?.type_name ?? '';
    if (rdata.data.parent_type_name && rdata.data.type_name) {
        type_display_name = rdata.data.parent_type_name + ' > ' + type_display_name;
    }
    rdata.data.type_display_name = type_display_name;
    if (rdata.data.show_id) {
        rdata.data.selected_show_id = rdata.data.show_id;
        rdata.data.current_show_id = rdata.data.show_id;
    }
    delete rdata.data.warehouse_locations;
    return rdata;
};

const processGetShowData = (rdata) => {
    if (rdata.data.org_id) {
        rdata.data.current_org_id = rdata.data.org_id;
    }
    return rdata;
};
