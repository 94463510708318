import React, {useCallback, useEffect} from 'react';
import {
    useHistory,
    useLocation
} from 'react-router-dom';
import {connect} from 'react-redux';
import {Layout} from 'react-admin';
import ParrotAppBar from './ParrotAppBar';
import ParrotSidebar from './Sidebar';
import ParrotMenu from './Menu';
import ParrotNotification from './Notification';
import {checkAuthentication} from '../utils/authFunctions';
import {hasSpace} from '../utils/dataFunctions';
import {getLocationElements} from '../utils/pageFunctions';
import PTheme from '../global/theme';
import {makeStyles} from '@material-ui/core/styles';

const useLayoutStyles = makeStyles({
    root: {
        backgroundColor: PTheme.palette.canvasColor
    },
    content: {
        paddingTop: 16,
        flexWrap: 'wrap'
    }
});

const ParrotLayoutWithTheme = ({keycloakReady, keycloakAuthenticated, tokens, ...props}) => {

    let history = useHistory();
    let location = useLocation();
    const classes = useLayoutStyles();

    const isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);

    const pushHistory = useCallback((route) => {
        if (route && !hasSpace(route)) {
            history.push(route);
        }
    }, [history]);

    // Effect: Store destination for redirection after Keycloak login
    useEffect(
        () => {
            const windowLocation = getLocationElements();
            if (
                windowLocation.route.indexOf('state=') === -1
            ) {
                // Store destination route without search queries or Keycloak state
                localStorage.setItem('destination', windowLocation.route);
            }

        }, [location]
    );

    // Effect: Set location
    useEffect(
        () => {
            const storedDestination = localStorage.getItem('destination');
            if (keycloakReady && isAuthenticated) {
                // If a destination was stored upon Keycloak redirection, push it to history
                if (storedDestination && storedDestination !== (location.pathname + location.search)) {
                    pushHistory(storedDestination);
                }
            }

        }, [keycloakReady, history, isAuthenticated, location, pushHistory]
    );

    return (
        <Layout
            {...props}
            appBar={ParrotAppBar}
            sidebar={ParrotSidebar}
            menu={ParrotMenu}
            notification={ParrotNotification}
            classes={classes}
        />
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default connect(
    mapStateToProps,
    {}
)(ParrotLayoutWithTheme);
