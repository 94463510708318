import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SelectFromListInput from './SelectFromListInput';
import TitleCreateDialog from './TitleCreateDialog';
import Button from '@material-ui/core/Button';

const btnStyles = {
    addNewButton: {
        display: 'inline-block',
        margin: '5px 0 1em 0'
    }
};

class OrgShowSelectFromListInput extends Component {
    constructor(props) {
        super(props);
        const selectedOrg = (this.props.selectedOrg) ? this.props.selectedOrg : null;
        const selectedOrgId = (this.props.selectedOrgId) ? this.props.selectedOrgId : '';
        this.state = {
            createTitleDialogOpen: false,
            selectedOrg: selectedOrg,
            selectedOrgId: selectedOrgId,
            selectedShow: null,
            selectedShowId: '',
            showChoicesAll: [],
            showChoicesFiltered: [],
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let updateOrg = false;
        let updateOrgId = false;
        let org = prevState.selectedOrg;
        let orgId = prevState.selectedOrgId;
        if (nextProps.selectedOrg && nextProps.selectedOrg !== prevState.selectedOrg) {
            updateOrg = true;
            org = nextProps.selectedOrg;
        }
        if (nextProps.selectedOrgId && nextProps.selectedOrgId !== prevState.selectedOrgId) {
            updateOrgId = true;
            orgId = nextProps.selectedOrgId;
        }
        if (updateOrg || updateOrgId) {
            return {
                selectedOrg: org,
                selectedOrgId: orgId
            }
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedOrgId && this.props.selectedOrgId !== prevProps.selectedOrgId) {
            this.handleOrgSelect(this.props.selectedOrgId);
        }
    }

    handleOrgSelect = (value) => {
        this.setState({
            selectedOrgId: value,
            selectedShowId: ''
        }, () => {
            const onSelectOrg = this.props.onSelectOrg;
            if (typeof onSelectOrg === 'function') {
                onSelectOrg(value);
            }
        });
    };

    handleShowSelect = (value) => {
        this.setState({
            selectedShowId: value
        }, () => {
            const onSelectShow = this.props.onSelectShow;
            if (typeof onSelectShow === 'function') {
                onSelectShow(value);
            }
        });
    };

    handleTitleCreated = (item) => {
        if (item.id) {
            this.setState({
                selectedShow: item,
                selectedShowId: item.id
            }, () => {
                const onSelectShow = this.props.onSelectShow;
                if (typeof onSelectShow === 'function') {
                    onSelectShow(item.id);
                }
            });
        }
    };

    displayCreateTitleDialog = (open) => {
        this.setState({
            createTitleDialogOpen: open
        });
    };

    render() {
        const {
            includeTitleCreation,
            inline,
            isRequired,
            orgLabel,
            orgSrc,
            selectOrg,
            showLabel,
            showSrc
        } = this.props;

        const {
            createTitleDialogOpen,
            selectedOrg,
            selectedOrgId,
            selectedShow
        } = this.state;

        let newTitle = 'Create New Title';
        if (selectedOrg && selectedOrg.name) {
            newTitle += ' for ' + selectedOrg.name;
        }

        const inputClass = (inline)
            ? 'input-inline third'
            : '';

        return (
            <>
                {selectOrg &&
                    <SelectFromListInput
                        inAdminForm={true}
                        source={orgSrc}
                        resourceName="Organization"
                        listLabel={orgLabel}
                        itemPlural="Clients"
                        listFilters={{
                            active: true
                        }}
                        className={inputClass}
                        isRequired={isRequired}
                        returnType="string"
                        onChangeFunc={value => this.handleOrgSelect(value)}
                    />
                }
                <SelectFromListInput
                    inAdminForm={true}
                    source={showSrc}
                    resourceName="ShowByOrg"
                    selectedObject={(selectedShow)
                        ? {id: selectedShow.id, name: selectedShow.name}
                        : undefined}
                    listLabel={showLabel}
                    itemPlural="Titles"
                    listFilters={{
                        active: true,
                        org_id: selectedOrgId
                    }}
                    className={inputClass}
                    isRequired={isRequired}
                    disabled={(!selectedOrgId)}
                    returnType="string"
                    refresh={true}
                    onChangeFunc={value => this.handleShowSelect(value)}
                />
                {includeTitleCreation &&
                    <>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => this.displayCreateTitleDialog(true)}
                            style={btnStyles.addNewButton}
                            disabled={(!selectedOrgId)}
                        >
                            {newTitle}
                        </Button>
                        <TitleCreateDialog
                            selectedOrgId={selectedOrgId}
                            openDialog={createTitleDialogOpen}
                            onCompleteFunc={(e) => this.handleTitleCreated(e)}
                            onCloseFunc={() => this.displayCreateTitleDialog(false)}
                        />
                    </>
                }
            </>
        );
    }
}

OrgShowSelectFromListInput.propTypes = {
    addLabel: PropTypes.bool,
    includeTitleCreation: PropTypes.bool,
    inline: PropTypes.bool,
    isRequired: PropTypes.bool,
    onSelectOrg: PropTypes.func,
    onSelectShow: PropTypes.func,
    orgLabel: PropTypes.string,
    orgSrc: PropTypes.string,
    selectOrg: PropTypes.bool,
    selectedOrg: PropTypes.object,
    selectedOrgId: PropTypes.any,
    showLabel: PropTypes.string,
    showSrc: PropTypes.string,
};

OrgShowSelectFromListInput.defaultProps = {
    addLabel: true,
    includeTitleCreation: false,
    inline: true,
    isRequired: false,
    orgLabel: 'Select a Client',
    orgSource: 'selected_org_id',
    selectOrg: true,
    selectedOrg: null,
    selectedOrgId: '',
    showLabel: 'Select a Title',
    showSource: 'selected_show_id'
};

export default OrgShowSelectFromListInput;
