export const ORDER_STATUS = [
    {id: 1, name: 'Pending'},
    {id: 2, name: 'In Progress'},
    {id: 7, name: 'In Production'},
    {id: 6, name: 'Ready to Ship'},
    {id: 4, name: 'Shipped'},
    {id: 5, name: 'Archived'}
];

export const ORDER_STATUS_NO_CANCEL = [
    {id: 1, name: 'Pending'},
    {id: 2, name: 'In Progress'},
    {id: 7, name: 'In Production'},
    {id: 6, name: 'Ready to Ship'},
    {id: 4, name: 'Shipped'},
    {id: 5, name: 'Archived'}
];
