import React from 'react';

export const fdStyles = {
    field: {
        display: 'block',
        width: 'auto',
        fontSize: '14px',
        color: '#524b50',
        lineHeight: '1.4',
        letterSpacing: '0.015em',
        verticalAlign: 'bottom',
        margin: 0
    },
    description: {
        flex: '1 1 40%',
        display: 'inline-block',
        minHeight: '2em',
        fontSize: '12px',
    },
    header: {
        fontSize: '16px',
        fontWeight: 600,
    },
    inline: {
        display: 'inline-block',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '46px',
        verticalAlign: 'middle',
        margin: '0 1em 0 0',
    },
    inlineInput: {
        height: 75
    },
    instructions: {
        fontWeight: 600,
    },
    marginBottom: {
        margin: '0 0 1em 0',
    }
};

/**
 * React-Final-Form capable container for text content
 *
 * @param props
 * @return {*}
 * @constructor
 */
const FieldDescription = (props) => {

    const styles = (props.style) ? props.style : {};

    let fieldStyle = {...fdStyles.field, ...styles};
    if (props.marginBottom) {
        fieldStyle = {...fdStyles.field, ...fdStyles.marginBottom, ...styles};
    }
    if (props.description) {
        fieldStyle = (props.marginBottom)
            ? {...fdStyles.field, ...fdStyles.description, ...fdStyles.marginBottom, ...styles}
            : {...fdStyles.field, ...fdStyles.description, ...styles};
    }
    if (props.header) {
        fieldStyle = (props.marginBottom)
            ? {...fdStyles.field, ...fdStyles.header, ...fdStyles.marginBottom, ...styles}
            : {...fdStyles.field, ...fdStyles.header, ...styles};
    }
    if (props.inline) {
        fieldStyle = (props.type === 'input')
            ? {...fdStyles.field, ...fdStyles.inline, ...fdStyles.inlineInput, ...styles}
            : {...fdStyles.field, ...fdStyles.inline, ...styles};
    }
    if (props.instructions) {
        fieldStyle = (props.marginBottom)
            ? {...fdStyles.field, ...fdStyles.instructions, ...fdStyles.marginBottom, ...styles}
            : {...fdStyles.field, ...fdStyles.instructions, ...styles};
    }

    return (
        <span style={fieldStyle}>
            {props.text &&
                <>
                    {props.text}
                </>
            }
        </span>
    )
};

export default FieldDescription;
