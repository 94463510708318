import * as React from "react";
import {
    List,
    Filter,
    SimpleList,
    SimpleShowLayout,
    BooleanField,
    TextField,
    SelectInput,
    ExportButton,
    ListButton,
    TopToolbar
} from 'react-admin';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import ListEmpty from '../components/ListEmpty';
import FieldDescription from '../components/FieldDescription';
import {TITLE_COLUMNS} from '../global/listColumns';
import {showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';

export const ShowIcon = LocalActivityIcon;

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="active" label="Active"
                     choices={[
                         {id: true, name: 'Yes'},
                         {id: false, name: 'No'},
                     ]}
                     alwaysOn
                     allowEmpty={false}
        />
    </Filter>
);

const ListActions = ({basePath, data}) => {
    return (
        <TopToolbar>
            <ExportButton/>
        </TopToolbar>
    )
};

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Titles"><ShowIcon style={viewStyles.avatarSvg}/></Avatar>Titles</span>;

const showButtonColumn = showButtonColumnObject();

const listColumns = [...TITLE_COLUMNS, showButtonColumn];

const listSort = {field: 'org_name', order: 'ASC'};

export const ShowList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List title={ListTitle}
              sort={listSort}
              perPage={12}
              actions={<ListActions/>}
              filters={<ListFilter/>}
              filterDefaultValues={{active: true}}
              bulkActionButtons={false}
              pagination={false}
              empty={<ListEmpty props={{namePlural: 'Titles', nameSingle: 'Title', connector: 'a'}}/>}
              {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.org_name}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable columns={listColumns} sort={listSort} messageEmpty=" No Titles found." {...props}/>
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowActions = ({basePath, data}) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath}/>
        </TopToolbar>
    )
};

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Titles"><ShowIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.name}` : 'Title'}</span>;
};

export const ShowShow = (props) => (
    <Show title={<ShowTitle/>} actions={<ShowActions/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`
                Review Title details below.
                `}
            />
            <TextField label="Title ID" source="id" className="input-inline input-120"/>
            <TextField label="Client" source="org_name" className="input-inline input-120"/>
            <TextField label="Title Name" source="name" className="field-bg"/>
            <BooleanField label="Active" source="active"/>
        </SimpleShowLayout>
    </Show>
);
