import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
    SimpleShowLayout,
    TextField,
} from 'react-admin';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import FieldDivider from './FieldDivider';
import ProductImage from './ProductImage';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useDialogStyles = makeStyles({
    root: {
        margin: 0,
        padding: 12,
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 8,
        color: '#666666',
    },
});

/**
 * A Material UI Dialog to display Product Details
 *
 * @param openDialog
 * @param productId
 * @param onCloseFunc
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ProductDetailDialog = (
    {
        openDialog,
        productId,
        onCloseFunc,
        ...props
    }) => {

    const [record, setRecord] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(
        () => {
            const fetchItem = () => {
                const cfg = resources.Product.GET_ONE({id: productId});
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders()
                }).then((response) => {
                    if (response.data) {
                        let type_display_name = response.data?.type_name ?? '';
                        if (response.data.parent_type_name && response.data.type_name) {
                            type_display_name = response.data.parent_type_name + ' > ' + type_display_name;
                        }
                        response.data.type_display_name = type_display_name;
                        setRecord(response.data);
                        setOpen(true);
                    } else {
                        setRecord(null);
                    }
                }).catch((error) => {
                    console.log('error response for Product: ', error);
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                });
            };

            // If current Item ID is set or has changed, fetch that Product
            if (
                (productId && !record) ||
                (productId && record && (parseInt(record.id, 10) !== parseInt(productId, 10)))
            ) {
                fetchItem();
            }

        }, [productId, record]
    );

    const handleClose = () => {
        setOpen(false);
        setRecord(null);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const classes = useDialogStyles();

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="Product-Detail"
                open={open}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">
                        {record && record.title &&
                            <>
                                Product {record.title}
                            </>
                        }
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    {record &&
                        <SimpleShowLayout record={record} className="show-modal">
                            <TextField label="ID" source="id" className="input-inline input-220"/>
                            <TextField label="SKU" source="sku" className="input-inline input-220"/>
                            <FieldDivider type="break" margin="bottom"/>
                            <TextField label="Title" source="show_name" className="input-inline input-220"/>
                            <TextField label="Product Category" source="type_display_name" className="input-inline input-220"/>
                            <FieldDivider type="break" margin="bottom"/>
                            <TextField label="Name of Product" source="title" className="field-bg"/>
                            <ProductImage renderType="show" label="Product Image" allowUpload={false} {...props}/>
                            <TextField label="Description" source="description" className="field-bg"/>
                            <TextField label="Total Qty" source="qty" className="input-inline input-120"/>
                        </SimpleShowLayout>
                    }
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default ProductDetailDialog;
