import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useLoadingStyles = makeStyles({
    root: {
        padding: '10px 2em 10px 2em'
    }
});

const useLoadingContentStyles = makeStyles({
    root: {
        display: 'inline-block',
        fontWeight: 500,
        margin: '0 0.5em',
        verticalAlign: 'bottom'
    }
});

const loadingContentStyles = {
    display: 'inline-block',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.015em',
    margin: '0 0.5em',
    verticalAlign: 'bottom'
};

const ListLoading = ({text = 'Loading...'}) => {

    const classes = useLoadingStyles();
    const contentClasses = useLoadingContentStyles();

    return (
        <Box textAlign="left" m={1} classes={classes}>
            <CircularProgress size={30} classes={contentClasses}/>
            <p style={loadingContentStyles}>{text}</p>
        </Box>
    )
};

export default ListLoading;
