import Config from '../config';

const keycloakReducer = (state = Config.keycloakState, action) => {
    if (action.type === 'SET_KEYCLOAK_AUTHENTICATED') {
        return {
            ...state,
            keycloakAuthenticated: action.authenticated
        }
    }
    if (action.type === 'SET_KEYCLOAK_READY') {
        return {
            ...state,
            keycloakReady: true
        }
    }
    return state;
};

export default keycloakReducer;
