import React from 'react';
import {Sidebar} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useSidebarStyles = makeStyles({
    drawerPaper: {
        margin: 0,
        padding: '10px 0 0 0'
    },
});

/**
 * Sidebar
 *
 * @param props
 * @return {*}
 * @constructor
 */
const ParrotSidebar = (props) => {
    const classes = useSidebarStyles();
    return (
        <Sidebar classes={classes} {...props} />
    );
};

export default ParrotSidebar;
