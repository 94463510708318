import React from 'react';
import {
    EditButton,
    ShowButton
} from 'react-admin';
import DeleteWithDialog from '../components/DeleteWithDialog';

/**
 * Default filter for React Table
 *
 * @param filterValue
 * @param setFilter
 * @param onUpdate
 * @return {JSX.Element}
 * @constructor
 */
export const DefaultColumnFilter = (
    {
        column: {id, filterValue, setFilter}
    }) => {
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search"
            className="table-filter-default"
        />
    )
};

/**
 * Filter for React Table with auto-focus
 *
 * @param filterValue
 * @param setFilter
 * @param onUpdate
 * @return {JSX.Element}
 * @constructor
 */
export const FocusedColumnFilter = (
    {
        column: {id, isSorted, filterValue, setFilter}
    }) => {
    return (
        <input
            autoFocus={(isSorted) ? true : undefined}
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder="Search"
            className="table-filter-default"
        />
    )
};

/**
 * Default filter for React Table with total count
 *
 * @param filterValue
 * @param preFilteredRows
 * @param setFilter
 * @return {JSX.Element}
 * @constructor
 */
export const DefaultColumnFilterWithRecord = (
    {
        column: {filterValue, preFilteredRows, setFilter},
    }) => {

    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search ${count} records...`}
            className="table-filter-default"
        />
    )
};

/**
 * Return React Table column object for Edit Button
 *
 */
export const editButtonColumnObject = () => {
    return {
        Header: () => null,
        id: 'list-edit',
        Cell: ({row, basePath}) => (
            <EditButton basePath={basePath} record={row.original} label="" title="Edit" className="table-icon-button"/>
        ),
        width: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true
    }
};

/**
 * Return React Table column object for Delete Button
 *
 */
export const deleteButtonColumnObject = (label, resourceFile, resourceName, resourceParam) => {
    return {
        Header: () => null,
        id: 'list-delete',
        Cell: ({row}) => (
            <DeleteWithDialog
                type="icon"
                label={label}
                title={label}
                resourceFile={resourceFile}
                resourceName={resourceName}
                resourceParam={resourceParam}
                record={row.original}
                className="table-icon-button"
            />
        ),
        width: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true
    }
};

/**
 * Return React Table column object for Show Button
 *
 */
export const showButtonColumnObject = () => {
    return {
        Header: () => null,
        id: 'list-show',
        Cell: ({row, basePath}) => (
            <ShowButton basePath={basePath} record={row.original} label="" title="Details"
                        className="table-icon-button"/>
        ),
        width: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true
    }
};
