import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ListEmpty = (props) => {
    return (
        <Box textAlign="center" m={1}>
            <Typography variant="h6" paragraph>
                No {props.namePlural} found. Create {props.connector} {props.nameSingle} above.
            </Typography>
        </Box>
    )
};

export default ListEmpty;
