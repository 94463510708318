import React from 'react';
import {
    List,
    Filter,
    SimpleList,
    SimpleShowLayout,
    BooleanField,
    TextField,
    SelectInput,
    TextInput
} from 'react-admin';
import Emitter from '../utils/eventEmitter';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import ListEmpty from '../components/ListEmpty';
import ProductDetailDialogContainer from '../components/ProductDetailDialogContainer';
import ProductImage from '../components/ProductImage';
import ProductThumbnail from '../components/ProductThumbnail';
import SelectFromListInput from '../components/SelectFromListInput';
import {showButtonColumnObject} from '../utils/tableFunctions';
import {viewStyles} from '../global/styles';
import {tableStyles} from '../global/tableStyles';
import {useMediaQuery} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CategoryIcon from '@material-ui/icons/Category';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

export const ProductIcon = CategoryIcon;

/**
 * View: List
 */

const ListFilter = (props) => (
    <Filter {...props}>
        <TextInput
            key="filter_sku"
            label="Search by Product SKU"
            source="filter_sku"
            placeholder="SKU-N00012345"
            alwaysOn
            className="input-180"
        />
        <TextInput
            key="filter_title"
            label="Search Product Name"
            source="filter_title"
            alwaysOn
        />
        <SelectInput
            key="filter_active" source="active" label="Active"
            choices={[
                {id: true, name: 'Yes'},
                {id: false, name: 'No'},
            ]}
            alwaysOn
            allowEmpty={false}
            className="input-90"
        />
        <SelectInput
            key="filter_by" source="filter_by" label="Filter By:"
            choices={[
                {id: 'client', name: 'Client'},
                {id: 'show', name: 'Title'},
                {id: 'product_type', name: 'Category'}
            ]}
            alwaysOn
            allowEmpty={true}
            className="input-120"
        />
        <SelectFromListInput
            inAdminForm={true}
            source="filter_product_type_id"
            resourceName="ProductType"
            listLabel="By Category"
            itemPlural="Categories"
            returnType="string"
            listFilters={{
                active: props.filterValues.active
            }}
            isRequired={false}
            alwaysOn
            size="small"
            hasClear={true}
            className={(props.filterValues.filter_by && props.filterValues.filter_by === 'product_type') ? 'input-select-list' : 'input-hidden'}
        />
        <SelectFromListInput
            inAdminForm={true}
            source="filter_org_id"
            resourceName="Organization"
            listLabel="By Client"
            itemPlural="Clients"
            returnType="string"
            listFilters={{
                active: true
            }}
            isRequired={false}
            alwaysOn
            size="small"
            hasClear={true}
            className={(props.filterValues.filter_by && props.filterValues.filter_by === 'client') ? 'input-select-list' : 'input-hidden'}
        />
        <SelectFromListInput
            inAdminForm={true}
            source="filter_show_id"
            resourceName="Show"
            listLabel="By Title"
            itemPlural="Titles"
            returnType="string"
            listFilters={{
                active: true
            }}
            isRequired={false}
            alwaysOn
            size="small"
            hasClear={true}
            className={(props.filterValues.filter_by && props.filterValues.filter_by === 'show') ? 'input-select-list' : 'input-hidden'}
        />
    </Filter>
);

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Products"><ProductIcon style={viewStyles.avatarSvg}/></Avatar>Products</span>;

const showButtonColumn = showButtonColumnObject();

function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        return row.values[id] >= filterValue;
    })
}

filterGreaterThan.autoRemove = val => typeof val !== 'number';

const listColumns = [
    {
        Header: 'SKU',
        accessor: 'sku',
        sortType: 'basic',
        Cell: ({row}) => <span style={viewStyles.noWrap}>{(row.original.sku) ? row.original.sku : ''}</span>
    },
    {
        Header: 'Image',
        id: 'thumb',
        Cell: ({row}) => <ProductThumbnail label="Image" title={row.original.title}
                                           thumbnail={row.original.thumbnail}/>,
        disableFilters: true,
        disableSortBy: true
    },
    {
        Header: 'Client',
        accessor: 'org_name',
        sortType: 'basic',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.org_name) ? row.original.org_name : ''}</span>
    },
    {
        Header: 'Title',
        accessor: 'show_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.show_name) ? row.original.show_name : ''}</span>
    },
    {
        Header: 'Name',
        accessor: 'title',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.title) ? row.original.title : ''}</span>
    },
    {
        Header: 'Parent Category',
        accessor: 'parent_type_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.parent_type_name) ? row.original.parent_type_name : ''}</span>
    },
    {
        Header: 'Category',
        accessor: 'type_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.type_name) ? row.original.type_name : ''}</span>
    },
    {
        Header: () => (<span style={tableStyles.tdRight}>Qty</span>),
        accessor: 'qty',
        id: 'qty',
        sortType: 'basic',
        disableFilters: true,
        ClassName: 'right',
        width: 80,
        maxWidth: 80,
        Cell: ({row}) => <span>{row.original.qty}</span>
    },
    {
        Header: () => null,
        id: 'list-detail',
        Cell: ({row}) => (
            <span className="table-icon-button">
                    <IconButton
                        aria-label="Quick View"
                        title="Quick View"
                        color="primary"
                        onClick={() => {
                            Emitter.emit('selectedProductId', row.original.id);
                        }}
                    >
                            <InfoIcon/>
                        </IconButton>
                </span>
        ),
        width: 80,
        maxWidth: 80,
        disableFilters: true,
        disableSortBy: true
    },
    showButtonColumn
];

const listSort = {field: 'title', order: 'ASC'};

export const ProductList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <>
            <List title={ListTitle}
                  sort={listSort}
                  perPage={20}
                  filters={<ListFilter/>}
                  filterDefaultValues={{active: true, filter_by: 'client'}}
                  bulkActionButtons={false}
                  pagination={false}
                  empty={<ListEmpty props={{namePlural: 'Products', nameSingle: 'Product', connector: 'a'}}/>}
                  {...props}
            >
                {isSmall ? (
                    <SimpleList
                        primaryText={record => record.title}
                        secondaryText={record => record.description}
                    />
                ) : (
                    <DataTable
                        columns={listColumns}
                        sort={listSort}
                        listFilters="filterValues"
                        listFilter="filter_by"
                        messageFilter="Search or select a Filter to view Products."
                        messageEmpty=" No Products found."
                        {...props}
                    />
                )}
            </List>
            <ProductDetailDialogContainer/>
        </>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title"
                         alt="Product"><ProductIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `${record.title}` : 'Product'}</span>;
};

export const ProductShow = (props) => (
    <Show title={<ShowTitle/>} {...props}>
        <SimpleShowLayout className="show-layout">
            <FieldDescription
                instructions={true} marginBottom={true}
                text={`Review Product details below.`}
            />
            <TextField label="ID" source="id" className="input-inline input-280"/>
            <TextField label="SKU" source="sku" className="input-inline input-180"/>
            <FieldDivider type="break" margin="bottom"/>
            <TextField label="Name of Product" source="title" className="field-bg"/>
            <FieldDivider type="break" margin="bottom"/>
            <TextField label="Title" source="show_name" className="input-inline input-280"/>
            <TextField label="Product Category" source="type_display_name" className="input-inline input-320"/>
            <FieldDivider type="break" margin="bottom"/>
            <ProductImage renderType="show" label="Product Image" allowUpload={false} {...props}/>
            <TextField label="Description" source="description" className="field-bg"/>
            <FieldDivider type="divider" margin="bottom"/>
            <FieldDescription header={true} text={`Total Quantity`}/>
            <TextField label="" source="qty" className="input-180"/>
            <BooleanField label="Active" source="active" className="input-inline input-120"/>
        </SimpleShowLayout>
    </Show>
);
