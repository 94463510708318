const config = {
    currentTableData: {
        currentList: []
    },
    keycloakState: {
        keycloakAuthenticated: false,
        keycloakReady: false
    },
    routes: [
        {id: 'dashboard', needsAuth: false}
    ],
    tokensDefault: {
        idToken: null,
        token: null,
        refreshToken: null
    },
    urls: {
        api_url: ''
    }
};

export default config;
