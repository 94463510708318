import React, {cloneElement} from 'react';
import {
    List,
    Filter,
    SimpleList,
    SimpleShowLayout,
    TopToolbar,
    DateField,
    TextField,
    DateInput,
    SelectInput,
    TextInput,
    CreateButton,
    sanitizeListRestProps,
    useListContext,
    useListController,
    useShowController
} from 'react-admin';
import Show from '../components/admin/Show';
import DataTable from '../components/admin/DataTable';
import ContactDetails from '../components/ContactDetails';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import OrderItemListViewContainer from '../components/OrderItemListViewContainer';
import SelectFromListInput from '../components/SelectFromListInput';
import {getDefaultDateRanges} from '../utils/dataFunctions';
import {showButtonColumnObject} from '../utils/tableFunctions';
import {ORDER_QUERY_DEFAULTS, ORDER_RECEIPT_TABLE_FILTERS} from '../global/orderReceiptDefaults';
import {ORDER_STATUS} from '../global/orderStatus';
import {viewStyles} from '../global/styles';
import {useMediaQuery} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import OrdersIcon from '@material-ui/icons/Receipt';

export const OrderIcon = OrdersIcon;

const defaultDateRanges = getDefaultDateRanges();

/**
 * View: List
 */

const ListFilter = (props) => {

    const onFilterChange = (e) => {
        // Clear table quick-filters when top-level Filters change
        let updateFilters = false;
        let fName, fValue;
        if (e && e.target) {
            fName = e.target.name;
            fValue = e.target.value;
        }
        // If View Mode or Filter By changes:
        if (
            fName && fValue &&
            (fName === 'filter_list_view' || fName === 'filter_by')
        ) {
            const fValues = JSON.parse(JSON.stringify(props.filterValues));
            ORDER_RECEIPT_TABLE_FILTERS.forEach((filter) => {
                if (fValues[filter.key]) {
                    if (filter.clearOnFilterBy.includes(fValue) || fName === 'filter_list_view') {
                        fValues[filter.key] = undefined;
                        updateFilters = true;
                    }
                }
            });
            if (updateFilters && typeof props.setFilters === 'function') {
                fValues[fName] = fValue;
                props.setFilters(fValues);
            }
        }
    };

    return (
        <Filter {...props}>
            <SelectInput
                key="filter_by" source="filter_by" label="Filter By:"
                choices={[
                    {id: 'date_range', name: 'Date Range'},
                    {id: 'client', name: 'Client'},
                    {id: 'contact', name: 'Client Contact'},
                    {id: 'id', name: 'Order ID'},
                    {id: 'sku', name: 'Product SKU'},
                    {id: 'status', name: 'Status'}
                ]}
                alwaysOn
                allowEmpty={true}
                onChange={(e) => onFilterChange(e)}
                className="input-150"
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_org_id"
                resourceName="Organization"
                listLabel={(props.filterValues.filter_by === 'contact') ? 'Select Client' : 'By Client'}
                itemPlural="Clients"
                returnType="string"
                listFilters={{
                    active: true
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && (props.filterValues.filter_by === 'client' || props.filterValues.filter_by === 'contact')) ? 'input-select-list' : 'input-hidden'}
            />
            <SelectFromListInput
                inAdminForm={true}
                source="filter_contact_id"
                resourceName="Contact"
                listLabel="Select Contact"
                itemPlural="Contacts"
                returnType="string"
                listFilters={{
                    active: true,
                    params: {
                        filter: {
                            filter_org_id: props.filterValues.filter_org_id
                        },
                    }
                }}
                isRequired={false}
                alwaysOn
                size="small"
                hasClear={false}
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'contact') ? 'input-select-list' : 'input-hidden'}
            />
            <SelectInput
                key="filter_status_id"
                source="filter_status_id"
                label="By Status"
                choices={ORDER_STATUS}
                alwaysOn
                allowEmpty={false}
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'status') ? 'input-120' : 'input-hidden'}
            />
            <TextInput
                key="filter_id"
                label="Enter Order ID"
                source="filter_id"
                placeholder=""
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'id') ? 'input-180' : 'input-hidden'}
            />
            <TextInput
                key="filter_sku"
                label="Enter full Product SKU"
                source="filter_sku"
                placeholder="SKU-N00012345"
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={(props.filterValues.filter_by && props.filterValues.filter_by === 'sku') ? 'input-180' : 'input-hidden'}
            />
            <DateInput
                key="filter_from"
                source="filter_from" label="From"
                inputProps={{
                    min: defaultDateRanges.from,
                    max: defaultDateRanges.to
                }}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={
                    (
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'date_range') ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'client' && props.filterValues.filter_org_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'contact' && props.filterValues.filter_contact_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'status' && props.filterValues.filter_status_id)
                    )
                        ? 'input-180' : 'input-hidden'
                }
            />
            <DateInput
                key="filter_to"
                source="filter_to" label="To"
                inputProps={{
                    min: defaultDateRanges.from,
                    max: defaultDateRanges.to
                }}
                alwaysOn
                onChange={(e) => onFilterChange(e)}
                className={
                    (
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'date_range') ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'client' && props.filterValues.filter_org_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'contact' && props.filterValues.filter_contact_id) ||
                        (props.filterValues.filter_by && props.filterValues.filter_by === 'status' && props.filterValues.filter_status_id)
                    )
                        ? 'input-180' : 'input-hidden'
                }
            />
        </Filter>
    )
};

const ListTitle = <span><Avatar style={viewStyles.avatar} className="avatar-title"
                                alt="Orders"><OrderIcon style={viewStyles.avatarSvg}/></Avatar>Orders</span>;

const ListActions = (props) => {
    const {
        className,
        filters,
        ...rest
    } = props;
    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton basePath={basePath}/>
        </TopToolbar>
    );
};

const showButtonColumn = showButtonColumnObject();

const listSort = {field: ORDER_QUERY_DEFAULTS.sort_by, order: ORDER_QUERY_DEFAULTS.sort_order};
const listPagination = {page: ORDER_QUERY_DEFAULTS.page, perPage: ORDER_QUERY_DEFAULTS.per_page};

export const OrderList = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const lc = useListController(props);
    const fv = lc.filterValues;
    const filterDefaultValues = {
        filter_by: 'status',
        filter_status_id: 1,
        filter_from: defaultDateRanges.monthBack,
        filter_to: defaultDateRanges.to
    };

    // Assemble List columns based on selected Filter By values
    const clientCol = {
        Header: 'Client',
        accessor: 'org_name',
        sortType: 'basic',
        sortBy: 'org_id',
        Cell: ({row}) => <span
            style={viewStyles.noWrap}>{(row.original.org_name) ? row.original.org_name : ''}</span>
    };
    const destinationCol = {
        Header: 'Destination',
        accessor: 'ship_to_name',
        sortType: 'basic',
        sortBy: 'ship_to_id',
        Cell: ({row}) =>
            <span>{(row.original.ship_to && row.original.ship_to_name) ? row.original.ship_to_name : ''}</span>
    };
    const statusCol = {
        Header: 'Status',
        accessor: 'order_status',
        sortType: 'basic',
        sortBy: 'status_id',
        Cell: ({row}) => <span>{(row.original.order_status) ? row.original.order_status : ''}</span>
    };

    // Create initial columns
    const listColumns = [
        {
            Header: 'Order ID',
            accessor: 'id',
            sortType: 'basic',
            width: 90,
            maxWidth: 90,
        },
        {
            Header: 'Created On',
            accessor: 'created_on',
            sortType: 'datetime',
            disableFilters: true,
            Cell: ({row}) => <span>{(row.original.created_on_string) ? row.original.created_on_string : ''}</span>
        }
    ];

    // Exclude columns based on Filter By (Client, Contact)
    if (fv?.filter_by) {
        if (
            (!fv.filter_org_id) ||
            (fv.filter_by !== 'client' && fv.filter_by !== 'contact')
        ) {
            listColumns.push(clientCol);
        }
    } else {
        // If Filter By is unselected, display columns
        listColumns.push(clientCol);
    }

    // Exclude columns based on Filter By (Contact, Status)
    if (fv?.filter_by) {
        if (!fv.filter_contact_id || fv.filter_by !== 'contact') {
            listColumns.push(destinationCol);
        }
        if (!fv.filter_status_id || fv.filter_by !== 'status') {
            listColumns.push(statusCol);
        }
    } else {
        // If Filter By is unselected, display columns
        listColumns.push(destinationCol, statusCol);
    }

    listColumns.push(
        {
            Header: 'Created By',
            accessor: 'created_by',
            sortType: 'basic',
            Cell: ({row}) => <span>{(row.original.created_by) ? row.original.created_by : ''}</span>
        },
        showButtonColumn
    );

    return (
        <List
            title={ListTitle}
            sort={listSort}
            perPage={listPagination.perPage}
            actions={<ListActions/>}
            filters={<ListFilter setFilters={lc.setFilters}/>}
            filterDefaultValues={filterDefaultValues}
            bulkActionButtons={false}
            pagination={false}
            {...props}
        >
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.org_name}
                    secondaryText={record => record.ship_to_name}
                    tertiaryText={record => record.id}
                />
            ) : (
                <DataTable
                    columns={listColumns}
                    sort={listSort}
                    listFilters="filterValues"
                    listFilter="filter_by"
                    messageFilter="Select a Filter to view Orders."
                    messageEmpty=" No Orders found. Please select a filter to view Orders."
                    storeForExport={false}
                    manualQuickFilters={true}
                    manualSortAndPagination={true}
                    {...props}
                />
            )}
        </List>
    )
};

/**
 * View: Show
 */

const ShowTitle = ({record}) => {
    return <span><Avatar style={viewStyles.avatar} className="avatar-title" alt="Orders"><OrderIcon
        style={viewStyles.avatarSvg}/></Avatar>{record ? `Order #${record.id}` : 'Order'}</span>;
};

export const OrderShow = (props) => {
    const controllerProps = useShowController(props);

    return (
        <Show title={<ShowTitle/>} {...props} {...controllerProps}>
            <SimpleShowLayout className="show-layout">
                <FieldDescription
                    text="Review Order details below. To update this Order, please contact Parrot Media."
                    instructions={true} marginBottom={true}
                />
                <TextField label="Order ID" source="id" className="input-inline input-900"/>
                <TextField label="Client" source="org_name" className="input-inline input-320"/>
                <TextField label="Order Status" source="order_status" className="input-inline input-120"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Order Details" instructions={true} marginBottom={true}/>
                <TextField label="Created By" source="created_by" className="input-inline input-220"/>
                <DateField label="Created On" source="created_on" className="input-inline input-220"/>
                <FieldDivider type="break"/>
                <TextField label="Carrier" source="shipped_with_name" className="input-inline input-220"/>
                <TextField label="Method" source="shipping_type_name" className="input-inline input-220"/>
                <FieldDivider type="break"/>
                <TextField label="Tracking Number" source="tracking_number" className="field-bg"/>
                <TextField label="Notes" source="client_notes" className="field-bg has-breaks"/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Products in Order" instructions={true} marginBottom={true}/>
                <OrderItemListViewContainer hasEditQty={false} {...props}/>

                <FieldDivider type="divider"/>
                <FieldDescription text="Shipping Details" instructions={true} marginBottom={true}/>
                <ContactDetails record={controllerProps.record}/>
            </SimpleShowLayout>
        </Show>
    )
};
