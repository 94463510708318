import * as React from 'react';
import {
    Redirect,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import {checkAuthentication} from '../utils/authFunctions';

/**
 * Login Page
 *
 * @param keycloakReady
 * @param keycloakAuthenticated
 * @param tokens
 * @return {*}
 * @constructor
 */
const LoginPage = ({keycloakReady, keycloakAuthenticated, tokens}) => {

    let isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);
    if (keycloakReady && isAuthenticated && tokens.token) {
        // If Login route loads due to a refresh, check stored destination
        const destination = localStorage.getItem('destination');
        const redirect = destination || '/';
        localStorage.removeItem('destination');
        return <Redirect to={redirect}/>
    }

    return (
        <>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(LoginPage)
);
