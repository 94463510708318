import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTable, usePagination, useFilters, useSortBy} from 'react-table';
import {useListContext} from 'react-admin';
import {setCurrentTableData} from '../../store/actions';
import IconSortArrowDown from '../../css/images/IconSortArrowDown';
import IconSortArrowUp from '../../css/images/IconSortArrowUp';
import ListLoading from '../ListLoading';
import ListMessage from '../ListMessage';
import {DefaultColumnFilter} from '../../utils/tableFunctions';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

/**
 * A component that renders a React Table from React-Admin data structure
 *
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */
const DataTable = (props) => {
    const {
        columns,
        perPage,
        sort,
        listFilters = null,
        listFilter = null,
        messageEmpty = '',
        messageFilter = '',
        instructions = '',
        manualQuickFilters,
        manualSortAndPagination,
        setCurrentTableData,
        storeForExport
    } = props;

    const {
        basePath,
        data,
        filterValues,
        ids,
        loaded,
        setFilters,
        setPage,
        setSort,
        total
    } = useListContext(props);

    const [dataList, setDataList] = useState([]);
    const [currentTableList, setCurrentTableList] = useState([]);
    const [manualPageIndex, setManualPageIndex] = useState(0);

    const lastFilterRef = useRef(null);

    // Callback: Store current table data in Redux
    const storeCurrentTableData = useCallback((currentList) => {
        setCurrentTableData(currentList);
    }, [setCurrentTableData]);

    // Effect: Update table list state upon new data
    useEffect(() => {
        // Create an array of data the table can use based on React-Admin's ids and data
        const setTableData = () => {
            let list = [];
            let idLength = ids.length;
            if (idLength > 0) {
                // Assemble an array based on React-Admin ids and data
                for (let i = 0; i < idLength; i++) {
                    let id = ids[i];
                    let item = data[id];
                    if (item && item.id !== '') {
                        list.push(item);
                    }
                }
            }
            setDataList(list);
        };

        return setTableData();

    }, [ids, data]);

    // Memoized Table Data
    const tableData = useMemo(() => dataList, [dataList]);

    // Memoized Table Columns
    const tableColumns = useMemo(
        () => columns, [columns]
    )

    // Default Sort column
    const sortBy = (sort) ? {id: sort.field, desc: (sort.order) === 'DESC'} : {id: 'id', desc: false};

    // Initial sort & pagination values
    const initialState = {
        pageIndex: 0,
        pageSize: (perPage) ? perPage : 20,
        sortBy: [sortBy]
    };

    // Default Filter
    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }), []
    );

    // Table Definition
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        gotoPage,
        state: {pageIndex}
    } = useTable(
        {
            columns: tableColumns,
            data: tableData,
            defaultColumn: defaultColumn,
            initialState: initialState,
            manualFilters: manualQuickFilters,
            manualPagination: manualSortAndPagination,
            manualSortBy: manualSortAndPagination
        },
        useFilters,
        useSortBy,
        usePagination
    );

    // Effect: Store currently filtered and sorted rows
    useEffect(() => {
        const storeCurrentRows = () => {
            let list = [];
            if (page.length > 0) {
                page.forEach(function (row, idx) {
                    if (row.original?.id) {
                        list.push(row.original);
                    }
                });
            }
            const isNew = (JSON.stringify(list) !== JSON.stringify(currentTableList));
            if (list.length > 0 && isNew) {
                setCurrentTableList(list);
                storeCurrentTableData(list);
            }
        };

        if (storeForExport && page && page.length > 0) {
            storeCurrentRows();
        }
    }, [page, currentTableList, storeForExport, storeCurrentTableData]);

    // Display loading before resource has responded
    if (loaded === false) {
        return (
            <ListLoading/>
        )
    }

    // Pass sort click to parent if sorting is manual
    const handleManualSort = (id, sortBy, isSorted, isSortedDesc) => {
        const sortOrder = (isSorted) ? (isSortedDesc) ? 'DESC' : 'ASC' : 'ASC';
        const sortValue = (sortBy) ? sortBy : id;
        setSort(sortValue, sortOrder);
        if (manualPageIndex > 0) {
            setPage(1);
            setManualPageIndex(0);
        }
    };

    // Pass pagination click to parent if pagination is manual
    const handleManualPagination = (newPage) => {
        const toPage = Number(newPage);
        setPage(toPage + 1);
        setManualPageIndex(toPage);
    };

    const handleManualQuickFilters = (e, id, value) => {
        lastFilterRef.current = (value && value !== '') ? id : null;
        const updatedFilters = JSON.parse(JSON.stringify(filterValues));
        updatedFilters['s_' + id] = value;
        setFilters(updatedFilters, undefined, true);
    };

    // Set additional data to pass to cells
    const cellProps = {
        basePath: basePath
    };

    // Display message or prompt if empty
    let message;
    if (loaded && (ids.length === 0 || total === 0)) {
        if (listFilters && listFilter && props[listFilters] && props[listFilters][listFilter] && messageEmpty) {
            message = messageEmpty;
        } else if (listFilters && listFilter) {
            message = messageFilter;
        } else {
            message = messageEmpty;
        }
    }

    // Render table
    return (
        <>
            {instructions &&
                <p className="data-table-instructions">{instructions}</p>
            }
            {message &&
                <ListMessage text={message}/>
            }
            <div className="list-table-container">
                <CssBaseline/>
                <MaUTable {...getTableProps()} size="small" className="list-table">

                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <TableCell {...column.getHeaderProps()} key={`cell-${column.id}`}>
                                        {/* Table Header Label & Sort */}
                                        <div {...column.getSortByToggleProps()}
                                             {...manualSortAndPagination && {
                                                 onClick: () => handleManualSort(column.id, column.sortBy, column.isSorted, column.isSortedDesc)
                                             }}
                                        >
                                            {column.render('Header')}
                                            {column.canSort &&
                                                <span className="sort-icon">
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <IconSortArrowDown/>
                                                        : <IconSortArrowUp/>
                                                    : <IconSortArrowDown classes="disabled"/>}
                                            </span>
                                            }
                                        </div>
                                        {/* Table Header Filter */}
                                        <div className="column-filter">
                                            {column.canFilter && !manualQuickFilters
                                                ? column.render('Filter')
                                                : null
                                            }
                                            {column.canFilter && manualQuickFilters
                                                ? column.render(({column}) => (
                                                    <input
                                                        key={`s_${column.id}`}
                                                        id={`s_${column.id}`}
                                                        name={`s_${column.id}`}
                                                        defaultValue={(filterValues && filterValues['s_' + column.id]) || undefined}
                                                        autoFocus={!!(lastFilterRef.current && lastFilterRef.current === column.id)}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            handleManualQuickFilters(e, column.id, e.target.value);
                                                        }}
                                                        placeholder="Search"
                                                        className="table-filter-default"
                                                        style={{display: 'block'}}
                                                    />
                                                ))
                                                : null
                                            }
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>

                    {/* Table Body with paginated rows */}
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        const tdClass = (cell.column.ClassName) ? cell.column.ClassName : undefined;
                                        return (
                                            <TableCell {...cell.getCellProps()} className={tdClass}>
                                                {cell.render('Cell', cellProps)}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </MaUTable>

                {/* Table Pagination with optional manual pagination */}
                <TablePagination
                    component="div"
                    count={(manualSortAndPagination) ? total : rows.length}
                    page={(manualSortAndPagination) ? manualPageIndex : pageIndex}
                    rowsPerPage={initialState.pageSize}
                    rowsPerPageOptions={[]}
                    onPageChange={(e, newPage) => {
                        if (manualSortAndPagination) {
                            handleManualPagination(newPage);
                        } else {
                            gotoPage((newPage) ? Number(newPage) : 0);
                        }
                    }}
                />
            </div>
        </>
    )
};

const mapDispatchToProps = {
    setCurrentTableData: setCurrentTableData
};

DataTable.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.object,
    instructions: PropTypes.string,
    manualQuickFilters: PropTypes.bool,
    manualSortAndPagination: PropTypes.bool,
    onUpdate: PropTypes.func,
    storeForExport: PropTypes.bool,
    // Ignored
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    hover: PropTypes.bool,
    ids: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setPage: PropTypes.func,
    setPerPage: PropTypes.func,
    setSort: PropTypes.func,
    total: PropTypes.number,
    version: PropTypes.number,
    isRowSelectable: PropTypes.func,
};

DataTable.defaultProps = {
    manualQuickFilters: false,
    manualSortAndPagination: false,
    storeForExport: false
};

export default connect(
    null,
    mapDispatchToProps
)(DataTable);
