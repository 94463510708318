export const ORDER_QUERY_DEFAULTS = {
    filter_list_view: 'browse',
    filter_by: 'status',
    filter_status_id: 1,
    page: 1,
    per_page: 50,
    sort_by: 'created_on_date',
    sort_order: 'DESC'
};

export const ORDER_RECEIPT_TABLE_FILTERS = [
    {key: 's_approved_by', clearOnFilterBy: ['sku']},
    {key: 's_bill_ref_desc', clearOnFilterBy: ['bill_ref','sku']},
    {key: 's_billed_by_name', clearOnFilterBy: ['sku']},
    {key: 's_created_by', clearOnFilterBy: ['sku']},
    {key: 's_id', clearOnFilterBy: ['sku']},
    {key: 's_order_status', clearOnFilterBy: ['status', 'sku']},
    {key: 's_org_name', clearOnFilterBy: ['client', 'contact', 'sku']},
    {key: 's_shipped_with_name', clearOnFilterBy: ['sku']},
    {key: 's_shipping_type_name', clearOnFilterBy: ['sku']},
    {key: 's_tracking_number', clearOnFilterBy: ['sku']},
    {key: 's_ship_to_name', clearOnFilterBy: ['client', 'contact', 'sku']}
];

export const ORDER_BROWSE_EXPORT_COLUMNS = [
    {key: 'created_on_string', label: 'Created On'},
    {key: 'required_by_string', label: 'Required By'},
    {key: 'org_name', label: 'Client'},
    {key: 'id', label: 'Order ID'},
    {key: 'order_status', label: 'Status'},
    {key: 'shipped_with_name', label: 'Shipped With'},
    {key: 'shipping_type_name', label: 'Shipping Method'},
    {key: 'ship_to.name', label: 'Destination'},
    {key: 'created_by', label: 'Created By'},
    {key: 'notes', label: 'Notes'}
];
