import React from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import {getEnvironmentConfig} from './utils/dataFunctions';
import App from './App';
import './css/index.css';
import * as serviceWorker from './serviceWorker';

let configParams = {
    api_url: '',
    auth_client_id: '',
    auth_realm: '',
    auth_url: ''
};

// Load values from public configuration file
let envConfigRequest = getEnvironmentConfig();

envConfigRequest
    .then(data => {
        if (data) {

            // Set values
            configParams.api_url = data.api_url;
            configParams.auth_client_id = data.auth_client_id;
            configParams.auth_realm = data.auth_realm;
            configParams.auth_url = data.auth_url;

            // Store values to use for API calls
            localStorage.removeItem('server');
            localStorage.setItem('server', configParams.api_url);

            // Create new Keycloak instance with parameters
            const keycloak = new Keycloak({
                realm: configParams.auth_realm,
                clientId: configParams.auth_client_id,
                url: configParams.auth_url,
                // silentCheckSsoRedirectUri: process.env.PUBLIC_URL + '/silent-check-sso.html',
                enableLogging: true
            });

            const keycloakInitConfig = {
                onLoad: 'check-sso',
                promiseType: 'native',
                checkLoginIframe: true
            };

            // Render the application
            ReactDOM.render(
                <App
                    keycloak={keycloak}
                    keycloakInitConfig={keycloakInitConfig}
                    configParams={configParams}
                />,
                document.getElementById('admin')
            );

            // Set server items
            serviceWorker.unregister();

        } else {
            return Promise.reject('Failed to load Server Configuration');
        }
    }).catch(error => {
        console.log('Server Configuration Error: ', error);
        return Promise.reject('Failed to load Server Configuration');
    }
);

serviceWorker.unregister();
