import React from 'react';

export const fdStyles = {
    break: {display: 'block', width: '100%', height: 0, border: 'none', margin: 0},
    divider: {
        display: 'block',
        width: '100%',
        height: 1,
        backgroundColor: '#2d5033',
        border: 'none',
        margin: '1em 0 0.5em 0'
    },
    marginBottom: {marginBottom: '1em'},
    marginTop: {marginTop: '1em'},
};

/**
 * React-Final-Form capable container for divider content
 *
 * @param props
 * @return {*}
 * @constructor
 */
const FieldDivider = (props) => {

    let dividerStyle = fdStyles.break;
    if (props.type === 'break') {
        dividerStyle = fdStyles.break;
        if (props.margin === 'bottom') {
            dividerStyle = {...fdStyles.break, ...fdStyles.marginBottom};
        }
        if (props.margin === 'top') {
            dividerStyle = {...fdStyles.break, ...fdStyles.marginTop};
        }
        if (props.margin === 'top-bottom') {
            dividerStyle = {...fdStyles.break, ...fdStyles.marginTop, ...fdStyles.marginBottom};
        }
    }
    if (props.type === 'divider') {
        dividerStyle = fdStyles.divider;
        if (props.margin === 'bottom') {
            dividerStyle = {...fdStyles.divider, ...fdStyles.marginBottom};
        }
        if (props.margin === 'top') {
            dividerStyle = {...fdStyles.divider, ...fdStyles.marginTop};
        }
        if (props.margin === 'top-bottom') {
            dividerStyle = {...fdStyles.divider, ...fdStyles.marginTop, ...fdStyles.marginBottom};
        }
    }

    return (
        <hr style={dividerStyle}/>
    )
};

export default FieldDivider;
