import React from 'react';
import {Notification} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';

const useNotificationStyles = makeStyles({
    warning: {
        backgroundColor: '#b4212d'
    }
});

const ParrotNotification = props => {
    const classes = useNotificationStyles();
    return (
        <Notification {...props} autoHideDuration={5000} classes={classes}/>
    )
};

export default ParrotNotification;
