import React from 'react';

const IconSortArrowDown = ({classes = ''}) => {
    return (
        <span className={`icon-sort-arrow down ${classes}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path d="M5 8l4 4 4-4z"/>
            </svg>
        </span>
    )
};

export default IconSortArrowDown;
