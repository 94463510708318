import jwt_decode from 'jwt-decode';

/**
 * Return authentication status from stored token or Keycloak values
 *
 * @param keycloakToken
 * @param keycloakAuthenticated
 * @param storedToken
 * @param useStoredToken
 * @return {boolean}
 */
export const checkAuthentication = (keycloakToken, keycloakAuthenticated, storedToken = null, useStoredToken = true) => {
    let keycloakTokenExists = (keycloakToken !== undefined && keycloakToken !== null);

    // Check that Keycloak has an authenticated value and a token value:
    if (keycloakTokenExists && keycloakAuthenticated !== undefined) {
        if (tokenIsExpired(keycloakToken)) {
            return false;
        }

        // Return Keycloak's authenticated value
        return (keycloakAuthenticated);
    }

    return false;
};

/**
 * Determine if a token is expired from its exp value
 *
 * @param token
 */
export const tokenIsExpired = (token) => {
    let isExpired = false;
    const tokenValue = (token !== null) ? token : null;
    if (tokenValue) {
        // Decode the token and extract its expiration timestamp
        const decodedToken = jwt_decode(tokenValue);
        const tokenExp = decodedToken.exp;

        let nowDate = new Date();
        let expDate = new Date((tokenExp * 1000));
        isExpired = ((expDate - nowDate) <= 0);
    }
    return isExpired;
};

/**
 * Determine if user has role
 *
 * @param token
 * @param type
 * @param role
 * @return {boolean}
 */
export const hasRole = (token, type, role) => {
    const tokenValue = (token !== null) ? token : null;
    if (tokenValue) {
        const decodedToken = jwt_decode(tokenValue);
        let roles;
        let idx;
        if (type === 'realm') {
            roles = decodedToken.realm_access.roles;
        }
        if (type === 'resource') {
            roles = decodedToken.resource_access.account.roles;
        }
        idx = roles.findIndex(r => r === role);
        if (idx !== -1) {
            return true;
        }
    }
    return false;
};
