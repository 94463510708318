import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTable, usePagination, useFilters, useSortBy} from 'react-table';
import IconSortArrowDown from '../css/images/IconSortArrowDown';
import IconSortArrowUp from '../css/images/IconSortArrowUp';
import ListLoading from './ListLoading';
import ListMessage from './ListMessage';
import {DefaultColumnFilter, FocusedColumnFilter} from '../utils/tableFunctions';
import TablePagination from '@material-ui/core/TablePagination';

/**
 * A component that renders a React Table
 *
 * @param props
 * @return {JSX.Element|null}
 * @constructor
 */
const ListTable = (props) => {
    const {
        basePath,
        columns,
        data,
        includePagination,
        loaded,
        perPage,
        sort,
        listFilters = null,
        listFilter = null,
        messageEmpty = '',
        messageFilter = '',
        instructions = '',
        focusOnLoad = false,
        rowStyle,
        tableHeight
    } = props;

    // Set state of table list
    const [tableList, setTableList] = useState([]);

    // Update table list state upon new data
    useEffect(() => {
        // Create an array of data the table can use based on React-Admin's ids and data
        const setTableData = () => {
            let list = [];
            let total = data.length;
            if (total > 0) {
                // Assemble an array based on React-Admin ids and data
                for (let i = 0; i < total; i++) {
                    let item = data[i];
                    if (item) {
                        list.push(item);
                    }
                }
            }
            setTableList(list);
        };

        return setTableData();

    }, [data]);

    // Memoized Table Data
    const tableData = useMemo(() => tableList, [tableList]);

    // Memoized Table Columns
    const tableColumns = useMemo(() => columns, [columns]);

    // Default Sort column
    const sortBy = (sort) ? {id: sort.field, desc: (sort.order) === 'DESC'} : {id: 'id', desc: false};

    // Initial sort & pagination values
    const initialState = {
        pageIndex: 0,
        pageSize: (perPage) ? perPage : 10,
        sortBy: [sortBy]
    };

    // Default Filter
    const defaultColumn = React.useMemo(
        () => ({
            Filter: (focusOnLoad) ? FocusedColumnFilter : DefaultColumnFilter,
        }), [focusOnLoad]
    );

    // Table Definition
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        gotoPage,
        state: {pageIndex}
    } = useTable(
        {
            columns: tableColumns,
            data: tableData,
            defaultColumn: defaultColumn,
            initialState: initialState
        },
        useFilters,
        useSortBy,
        usePagination
    );

    // Display loading before resource has responded
    if (loaded === false) {
        return (
            <ListLoading/>
        )
    }

    // Display message or prompt if empty
    if (loaded && (data.length === 0)) {
        let message;
        if (listFilters && listFilter && props[listFilters] && props[listFilters][listFilter] && messageEmpty) {
            message = messageEmpty;
        } else if (listFilters && listFilter) {
            message = messageFilter;
        } else {
            message = messageEmpty;
        }
        return (
            <ListMessage text={message}/>
        )
    }

    // Set additional data to pass to cells
    const cellProps = {
        basePath: basePath
    };

    const tableStyle = (tableHeight) ? {height: tableHeight, overflow: 'auto'} : {};

    // Render table
    return (
        <>
            {instructions &&
                <p className="data-table-instructions">{instructions}</p>
            }
            <div className="list-table-container" style={tableStyle}>
                <table {...getTableProps()} className="list-table list-react-table">

                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {/* Table Header Label & Sort */}
                                    <div {...column.getSortByToggleProps()}>
                                        {column.render('Header')}
                                        {column.canSort &&
                                            <span className="sort-icon">
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <IconSortArrowDown/>
                                                        : <IconSortArrowUp/>
                                                    : <IconSortArrowDown classes="disabled"/>}
                                            </span>
                                        }
                                    </div>
                                    {/* Table Header Filter */}
                                    <div className="column-filter">
                                        {column.canFilter ? column.render('Filter') : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    {/* Table Body with paginated rows */}
                    <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} style={(rowStyle) ? rowStyle(row) : undefined}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell', cellProps)}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            {/* Table Pagination */}
            {includePagination &&
                <TablePagination
                    component="div"
                    count={rows.length}
                    page={pageIndex}
                    rowsPerPage={initialState.pageSize}
                    rowsPerPageOptions={[]}
                    onPageChange={(e, newPage) => {
                        gotoPage((newPage) ? Number(newPage) : 0)
                    }}
                />
            }
        </>
    )
};


ListTable.propTypes = {
    basePath: PropTypes.string,
    body: PropTypes.element,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    currentSort: PropTypes.shape({
        field: PropTypes.string,
        order: PropTypes.string,
    }),
    data: PropTypes.array,
    includePagination: PropTypes.bool,
    instructions: PropTypes.string,
    loaded: PropTypes.bool,
    tableHeight: PropTypes.number,
    // Ignored
    expand: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType]),
    hasBulkActions: PropTypes.bool,
    hover: PropTypes.bool,
    loading: PropTypes.bool,
    onSelect: PropTypes.func,
    onToggleItem: PropTypes.func,
    resource: PropTypes.string,
    rowClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    rowStyle: PropTypes.func,
    selectedIds: PropTypes.arrayOf(PropTypes.any),
    setSort: PropTypes.func,
    version: PropTypes.number,
    isRowSelectable: PropTypes.func,
};

export default ListTable;
