import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as baseResources} from '../resources';
import {postProcessData} from '../dataProviderPostProcess';
import {getGetHeaders} from '../utils/headers';
import ListLoading from './ListLoading';
import ListTable from './ListTable';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import {
    assembleListObject,
    CONTACT_COLUMNS,
    TITLE_COLUMNS,
    TITLE_BY_ORG_COLUMNS,
    SHIPPING_COMPANY_COLUMNS,
    SHIPPING_METHOD_COLUMNS,
    PRODUCT_TYPE_COLUMNS,
    CLIENT_COLUMNS
} from '../global/listColumns';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useDialogStyles = makeStyles({
    root: {
        margin: 0,
        padding: 12,
        width: '100%'
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 8,
        color: '#666666',
    },
    paper: {
        minHeight: 200
    }
});

/**
 * A Material UI Dialog to retrieve and display a table of items for selection
 *
 * @param itemPlural
 * @param listLabel
 * @param listSort
 * @param openDialog
 * @param resourceName
 * @param onCloseFunc
 * @param onSelectFunc
 * @param listFilters
 * @param refresh
 * @returns {JSX.Element}
 * @constructor
 */
const SelectFromListDialog = (
    {
        itemPlural,
        listLabel,
        listSort,
        openDialog,
        resourceName,
        onCloseFunc,
        onSelectFunc,
        listFilters = {active: true},
        refresh = false
    }) => {

    const [columns, setColumns] = useState([]);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterObject, setFilterObject] = useState(null);
    const [open, setOpen] = useState(false);

    // Callback: Set React-Table columns for intended Resource
    const setTableColumns = useCallback(() => {
        let columnArray = [];
        if (resourceName === 'Contact') {
            columnArray = [...CONTACT_COLUMNS];
        }
        if (resourceName === 'Organization') {
            columnArray = [...CLIENT_COLUMNS];
        }
        if (resourceName === 'ProductType' || resourceName === 'ParentProductType' || resourceName === 'ChildProductType') {
            columnArray = [...PRODUCT_TYPE_COLUMNS];
        }
        if (resourceName === 'ShippingCompany') {
            columnArray = [...SHIPPING_COMPANY_COLUMNS];
        }
        if (resourceName === 'ShippingMethod') {
            columnArray = [...SHIPPING_METHOD_COLUMNS];
        }
        if (resourceName === 'Show') {
            columnArray = [...TITLE_COLUMNS];
        }
        if (resourceName === 'ShowByOrg') {
            columnArray = [...TITLE_BY_ORG_COLUMNS];
        }

        // Add a Select button onto each row
        const selectButtonColumn = selectButtonColumnObject();
        columnArray.push(selectButtonColumn);
        setColumns(columnArray);
    }, [resourceName]); // eslint-disable-line react-hooks/exhaustive-deps

    // Callback: Get list
    const getItemList = useCallback(() => {
        let cfg = baseResources[resourceName].GET_LIST(listFilters);

        axios({
            method: cfg.method,
            url: cfg.uri,
            headers: getGetHeaders()
        })
            .then((response) => {
                if (response.data) {
                    // Post-process the data as if a List view
                    const rdata = postProcessData(resourceName, 'GET_LIST', {}, response);
                    setList(rdata.data);
                }
            })
            .catch((err) => {
                console.log('Error retrieving ' + itemPlural + ': ', err);
            })
            .finally(() => {
                setFilterObject(listFilters);
                setLoading(false);
            });
    }, [itemPlural, listFilters, resourceName]);

    // Effect: Get new list when directed to open or refresh
    useEffect(
        () => {
            const getList = () => {
                setOpen(true);
                // If filters change (such as an Org ID) or if the table should refresh, fetch data
                if (
                    JSON.stringify(filterObject) !== JSON.stringify(listFilters) ||
                    refresh === true
                ) {
                    setColumns([]);
                    setList([]);
                    setLoading(true);
                    setTableColumns();
                    getItemList();
                }
            };
            if (openDialog) {
                return getList();
            }

        }, [filterObject, listFilters, openDialog, refresh, resourceName, getItemList, setTableColumns]
    );

    // Create a Select button for each row
    const selectButtonColumnObject = (row) => {
        return {
            Header: () => null,
            id: 'list-select',
            Cell: ({row}) => {
                return (
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleSelectClick(row.original)}
                    >
                        Select
                    </Button>
                )
            },
            width: 180,
            maxWidth: 180,
            disableFilters: true,
            disableSortBy: true
        }
    };

    // Handle table row selection
    const handleSelectClick = (row) => {
        // Populate the object's ID and display label
        let obj = assembleListObject(resourceName, row);

        if (typeof onSelectFunc === 'function') {
            onSelectFunc(obj);
            handleClose();
        }
    };

    // Handle dialog closure
    const handleClose = () => {
        setOpen(false);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const classes = useDialogStyles();

    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="Select-From-Listing-Dialog"
                open={open}
                maxWidth={false}
                fullWidth={true}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">
                        {listLabel &&
                            <>
                                {listLabel}
                            </>
                        }
                    </Typography>
                    <IconButton
                        aria-label="close"
                        className={classes.closeButton}
                        onClick={handleClose}
                        tabIndex="-1"
                    >
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers className={classes.paper}>
                    {loading &&
                        <ListLoading/>
                    }
                    {columns && !loading &&
                        <div className="show-modal">
                            <ListTable
                                data={list}
                                columns={columns}
                                perPage={12}
                                includePagination={true}
                                loaded={true}
                                sort={listSort}
                                focusOnLoad={true}
                                messageEmpty={`No ${itemPlural} found.`}
                            />
                        </div>
                    }
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default SelectFromListDialog;
