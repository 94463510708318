import jwt_decode from 'jwt-decode';
import Emitter from './eventEmitter';

/**
 * Decode a JWT token and return its payload
 *
 * @param token
 * @return {any | undefined}
 */
export const decodeToken = (token) => {
    return jwt_decode(token);
};

/**
 * Store tokens from initial login
 *
 * @param token
 * @param idToken
 * @param refreshToken
 */
export const processKeycloakTokens = (token, idToken, refreshToken) => {
    if (token || refreshToken) {
        const tokens = {
            token: token,
            idToken: idToken,
            refreshToken: refreshToken
        };
        // Notify subscribers of received token
        Emitter.emit('accessToken', tokens.token);
    }
};

/**
 * Check if a token's expiration has passed
 *
 * @param exp
 * @return {boolean}
 */
export const isKeycloakTokenExpired = (exp) => {
    let nowDate = new Date();
    let expDate = new Date((exp * 1000));
    return (expDate - nowDate) <= 0;
};

/**
 * Extract User information from token
 *
 * @param token
 * @return {{email_verified: (*|string), name: (*|string), preferred_username: (*|string), given_name: (*|string), family_name: (*|string), email: (*|string)}|null}
 */
export const getUserInfoFromToken = (token) => {
    const dt = decodeToken(token);
    if (dt) {
        return {
            email: (dt && dt.email) ? dt.email : '',
            email_verified: (dt && dt.email_verified) ? dt.email_verified : '',
            family_name: (dt && dt.family_name) ? dt.family_name : '',
            given_name: (dt && dt.given_name) ? dt.given_name : '',
            name: (dt && dt.name) ? dt.name : '',
            preferred_username: (dt && dt.preferred_username) ? dt.preferred_username : ''
        };
    }
    return null;
};

/**
 * Determine if logged-in user is a sysop
 *
 * @param token
 * @returns {boolean}
 */
export const isSysop = (token) => {
    const dt = decodeToken(token);
    if (dt) {
        const roles = dt.realm_access?.roles;
        if (roles && roles.findIndex(r => r === 'sysop') !== -1) {
            return true;
        }
    }
    return false;
};

/**
 * Determine if logged-in user is a sysop or a Parrot Admin
 *
 * @param token
 * @returns {boolean}
 */
export const isSysopOrAdmin = (token) => {
    const dt = decodeToken(token);
    if (dt) {
        const roles = dt.realm_access?.roles;
        if (roles && roles.findIndex(r => r === 'sysop') !== -1) {
            return true;
        }
        if (roles && roles.findIndex(r => r === 'parrot_admin') !== -1) {
            return true;
        }
    }
    return false;
};

/**
 * Return a role from Realm Access for permissions
 *
 * @param token
 * @returns {string}
 */
export const getRole = (token) => {
    const dt = decodeToken(token);
    if (dt) {
        const roles = dt.realm_access?.roles ?? [];
        if (roles.findIndex(r => r === 'sysop') !== -1) {
            return 'sysop';
        }
        if (roles.findIndex(r => r === 'representative') !== -1) {
            return 'representative';
        }
        if (roles.findIndex(r => r === 'warehouse') !== -1) {
            return 'warehouse';
        }
    }
    return '';
};
