import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Emitter from '../utils/eventEmitter';
import {Field, Form} from 'react-final-form';
import classnames from 'classnames';
import {ListButton, TextInput, TopToolbar} from 'react-admin';
import ContactCreateDialog from '../components/ContactCreateDialog';
import ContactDetailsFromID from '../components/ContactDetailsFromID';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import FieldMessage from '../components/FieldMessage';
import GetListSelectInput from '../components/GetListSelectInput';
import OrderItemsAvailableListContainer from '../components/OrderItemsAvailableListContainer';
import OrderItemsList from '../components/OrderItemsList';
import SelectFromListInput from '../components/SelectFromListInput';
import {postItem, postOrder} from '../utils/apiOrderFunctions';
import {checkAuthentication} from '../utils/authFunctions';
import {assembleDefaultQueryString} from '../utils/dataFunctions';
import {viewStyles} from '../global/styles';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import OrdersIcon from '@material-ui/icons/Receipt';
import '../css/admin.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        borderRadius: 4,
        boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)'
    },
    appBar: {
        backgroundColor: '#9e9e9e',
        boxShadow: 'none',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    addNewButton: {
        display: 'inline-block',
        margin: '0 0 1em 0'
    },
    itemSelect: {
        display: 'inline-flex',
        width: 'calc(100% - 200px)'
    },
    tabIndicator: {
        backgroundColor: '#508242',
        height: 3
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        minHeight: theme.spacing(5),
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    }
}));

// Add a top toolbar with a List button
const CreateToolbar = props => {
    const classes = useStyles(props);
    const defaultOrderQueryString = assembleDefaultQueryString('Order');
    return (
        <TopToolbar {...props} className={classnames(classes.toolbar)}>
            <ListButton basePath={`/Order${defaultOrderQueryString}`}/>
        </TopToolbar>
    )
};

// Add an AppBar title
const CreateTitle = () => {
    const container = (typeof document !== 'undefined') ? document.getElementById('react-admin-title') : null;
    if (!container) return null;
    const titleElement = (
        <span><Avatar style={viewStyles.avatar} className="avatar-title"
                      alt="Create an Order"><OrdersIcon style={viewStyles.avatarSvg}/></Avatar>Create an Order</span>
    );
    return createPortal(titleElement, container);
};

const today = new Date();
const todayValue = today.toISOString();

/**
 * Form: Create Order with Product Manipulation
 *
 * @param props
 * @param keycloakReady
 * @param keycloakAuthenticated
 * @param tokens
 * @return {JSX.Element}
 * @constructor
 */
function OrderCreateForm({props, keycloakReady, keycloakAuthenticated, tokens}) {

    // This page requires authentication
    let isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);
    if (keycloakReady && !isAuthenticated) {
        Emitter.emit('updateKeycloak');
    }

    // State: Order Started, Products Added, Organization
    const [orderStartedState, setOrderStartedState] = useState(false);
    const [productsAddedState, setProductsAddedState] = useState(false);
    const [, setOrgState] = useState({
        id: null,
        name: null
    });
    // State: Started Messaging, Product Messaging
    const [startedMessageState, setStartedMessageState] = useState({
        error: false,
        message: '',
    });
    const [productMessageState, setProductMessageState] = useState({
        error: false,
        message: '',
    });
    // State: Order Complete, Error, Messaging
    const [orderState, setOrderState] = useState({
        complete: false,
        error: false,
        message: '',
        submitting: false
    });
    const [defaultOrderQueryString] = useState(assembleDefaultQueryString('Order'));
    // State: Tab display
    const [tabState, setTab] = useState(0);
    const handleTabChange = (e, newValue) => setTab(newValue);
    // State: Create Contact Dialog
    const [createContactDialogOpen, setCreateContactDialogOpen] = useState(false);
    const [refreshContacts, setRefreshContacts] = useState(false);
    // State: Product Qty update
    const [productToUpdate, setProductToUpdate] = useState(null);
    const [updateProducts, setUpdateProducts] = useState(false);
    const [productsSubmitted, setProductsSubmitted] = useState(false);

    /**
     * Process updates from Form Tab buttons
     *
     * @param form
     * @param step
     * @param values
     */
    const handleFormStep = (form, step, values) => {
        let data;
        let orderStarted = orderStartedState;

        // Step: Initialize an Order to retrieve an ID
        if (step === 'startOrder') {

            data = assembleFormData(step, values);

            postOrder('startOrder', data).then(result => {
                // Store the resulting ID; set Form stage; advance to Add Products Tab
                form.mutators.setValue('id', result.id);
                setOrderStartedState(true);
                setStartedMessageState({error: false, message: ''});
                setTab(1);
            }).catch(error => {
                form.mutators.setValue('id', null);
                setOrderStartedState(false);
                setProductsAddedState(false);
                setStartedMessageState({
                    error: true,
                    message: 'Sorry, there was an error creating this Order. Please check and resubmit.'
                });
                setTab(0);
            });
        }

        // Step: Go to the Add/Update Products tab
        if (step === 'goToAddProducts') {
            setOrderStartedState(true);
            setTab(1);
        }

        // Step: Go to the Create Order tab
        if (step === 'goToCreateOrder') {
            // Products have been added; set Form stage; advance to Create (Complete) Order Tab
            setOrderStartedState(orderStarted);
            setTab(2);
        }

        // Step: Submit all Products and create/finalize the Order
        if (step === 'createOrder') {

            // Assemble form data for Order call
            data = assembleFormData(step, values);
            let items = JSON.parse(JSON.stringify(values.items));

            setOrderState({
                complete: false,
                error: false,
                message: 'Submitting Order...',
                submitting: true
            });

            // Post Receipt function
            const postOrderData = (data) => {
                postOrder('createOrder', data).then(result => {
                    // Order has been completed
                    setOrderState({
                        complete: true,
                        error: false,
                        message: 'Your Order has been saved.',
                        submitting: false
                    })
                    handleFormStep(form, 'goToOrderCompleted', values);

                }).catch(error => {
                    setOrderState({
                        complete: false,
                        error: true,
                        message: 'Sorry, there was an error saving this Order.',
                        submitting: false
                    });
                });
            };

            // Sequentially post all Products in list
            let tasks = [];
            let taskLength = 0;
            for (let i = 0; i < items.length; i++) {
                const task = assembleProductSubmitData(items[i], values);
                const taskIdx = tasks.findIndex(r => r.product_id === task.product_id);
                if (!task.submitted && taskIdx === -1) {
                    tasks.push({
                        index: taskLength,
                        data: task
                    });
                    taskLength++;
                }
            }

            if (productsSubmitted) {
                // If Order is being submitted again due to an error, do not resubmit Products
                postOrderData(data);
            } else {
                // Start a Promise sequence with all tasks
                const starterPromise = Promise.resolve(null);
                tasks.reduce(
                    (p, spec) => p.then(() => postItem('addItem', spec.data)
                        .then((result) => {
                            // Update this Product as submitted
                            const itemsIdx = items.findIndex(r => r.product_id === result.product_id);
                            if (itemsIdx !== -1) {
                                items[itemsIdx].submitted = true;
                            }

                            // If all tasks in the list have completed:
                            if (spec.index >= (taskLength - 1)) {
                                // Update Product list with submitted states
                                form.mutators.setValue('items', items);
                                setProductsSubmitted(true);

                                // Products have been submitted; post Order
                                postOrderData(data);
                            }
                        })
                    ), starterPromise)
                    .catch((error) => {
                            console.log('Product Save error: ', error);
                            setOrderState({
                                complete: false,
                                error: true,
                                message: 'Sorry, there was an error saving Products for this Order.',
                                submitting: false
                            });
                            // Update Product items with submitted states
                            form.mutators.setValue('items', items);
                            setProductsSubmitted(false);
                            setTab(1);
                        }
                    );
            }
        }

        // Step: Go to the Completed Order tab
        if (step === 'goToOrderCompleted') {
            setTab(3);
        }
    };

    // Event: Update form values from active select fields
    const handleSetValueFromSelect = (e, field, form) => {
        const value = (e) ? e : '';
        if (field === 'org_id_select') {
            setOrgState({id: e.id, name: e.name});
            form.mutators.setValue('org_id', e?.id ?? '');
        }
        if (field === 'ship_to_id_select') {
            form.mutators.setValue('ship_to_id', e?.id ?? '');
        }
        if (field === 'shipped_with_select') {
            form.mutators.setValue('shipped_with', value);
            // Clear the Shipping Method upon new selection of Company
            form.mutators.setValue('shipping_type', '');
        }
        if (field === 'shipping_type_select') {
            form.mutators.setValue('shipping_type', value);
        }
    };

    // Event: add or remove Product or adjust its requested Quantity
    const handleAddOrRemoveItem = (e, form, values) => {
        const item = JSON.parse(JSON.stringify(e.item));
        const items = (values.items) ? JSON.parse(JSON.stringify(values.items)) : [];
        const idx = items.findIndex(r => r.product_id === item.product_id);
        let removeItem = false;
        let error = false;
        let message = '';
        let listUpdated = false;

        // If requested to add:
        if (e.add) {
            if (idx === -1) {
                // If not in the list, add the Order's ID, add the Product, and update the form
                item.order_id = values.id;
                item.qty = item.qty_requested;
                items.push(item);
                listUpdated = true;
            } else {
                if (item.qty_requested !== items[idx].qty) {
                    // Item is in list; update requested Qty
                    items[idx].qty = item.qty_requested;
                    listUpdated = true;

                    // If Qty has become 0, consider the item removed
                    if (items[idx].qty === 0) {
                        removeItem = true;
                    }
                } else {
                    // This item has already been added.
                    error = true;
                    message = item.title + ' already exists in the list.';
                }
            }
        }

        // Update Available table if tally table was edited:
        if (e.updateAvailable && items[idx]) {
            items[idx].qty_requested = item.qty_requested;
            items[idx].added = item.added;
            setUpdateProducts(true);
            setProductToUpdate(items[idx]);
            setTimeout(function () {
                setUpdateProducts(false);
                setProductToUpdate(null);
            }, 100);
        }

        // If requested to remove:
        if (!e.add && idx !== -1) {
            removeItem = true;
        }

        // If item is requested to be removed or its Qty is 0, remove it from the list
        if (removeItem) {
            items[idx].added = false;
            items.splice(idx, 1);
            listUpdated = true;
        }

        // Update form values
        if (listUpdated) {
            form.mutators.setValue('items', items);
            setProductsAddedState(true);
        }

        // Update message state
        setProductMessageState({error: error, message: message});

        if (items.length === 0) {
            setProductsAddedState(false);
        }
    };

    /**
     * Assemble data for Order POST calls based on form values
     *
     * @param step
     * @param values
     */
    const assembleFormData = (step, values) => {
        let data = {};
        if (step === 'startOrder') {
            data.org_id = values.org_id;
        }
        if (step === 'createOrder' || step === 'updateOrder') {
            data.id = values.id;
            data.org_id = values.org_id;
            data.ship_to_id = values.ship_to_id;
        }
        if (step === 'createOrder') {
            data.client_notes = (values.client_notes) ? values.client_notes : '';
            data.shipped_with = (values.shipped_with) ? values.shipped_with : '';
            data.shipping_type = (values.shipping_type) ? values.shipping_type : '';
            data.required_by = todayValue;
            data.ship_to_id = values.ship_to_id;

            if (data.shipped_with === '') {
                delete data.shipped_with;
            }
            if (data.shipping_type === '') {
                delete data.shipping_type;
            }
        }
        return data;
    };

    const assembleProductSubmitData = (item, values) => {
        let data = {};
        data.order_id = values.id;
        data.product_id = item.product_id;
        data.qty = item.qty;
        return data;
    };

    const handleSubmit = (e) => {
        // Form is not submitted conventionally
        e.preventDefault();
    };

    const handleContactCreated = (item, form, values) => {
        if (item.id) {
            form.mutators.setValue('ship_to_id', item.id);
            form.mutators.setValue('ship_to_name', item.name);
            form.mutators.setValue('ship_to_id_select', item.id);
            setRefreshContacts(true);
        }
    };

    const handleCloseCreateContactDialog = () => {
        setCreateContactDialogOpen(false);
    };

    const classes = useStyles();

    return (
        <>
            <CreateTitle/>
            <CreateToolbar {...props}/>
            <Form
                onSubmit={handleSubmit}
                mutators={{
                    setValue: ([field, value], state, {changeValue}) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render={({form, handleSubmit, values}) => {

                    // Determine disabled status for buttons and tabs
                    const isDisabled = (item) => {
                        const v = values;
                        switch (item) {
                            case 'submit0':
                                return (!v.org_id);
                            case 'submit1':
                                return (!productsAddedState);
                            case 'submit2':
                                return (!v.ship_to_id || !v.shipped_with || !v.shipping_type || orderState.complete || orderState.submitting);
                            case 'tab0':
                                return (orderStartedState);
                            case 'tab1':
                                return (!orderStartedState);
                            case 'tab2':
                                return (!productsAddedState || !orderStartedState);
                            case 'tab3':
                                return (!orderState.complete);
                            default:
                                return false;
                        }
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.root}>

                                {/* Tab Bar */}

                                <AppBar position="relative" className={classes.appBar}>
                                    <Tabs
                                        value={tabState}
                                        onChange={handleTabChange}
                                        aria-label="Order Form Tabs"
                                        classes={{indicator: classes.tabIndicator}}
                                    >
                                        <Tab id="order-tab-start" label="Start Order"
                                             aria-controls="order-tabpanel-start" disabled={isDisabled('tab0')}/>
                                        <Tab id="order-tab-add" label="Add Products"
                                             aria-controls="order-tabpanel-add" disabled={isDisabled('tab1')}/>
                                        <Tab id="order-tab-complete" label="Complete Order"
                                             aria-controls="order-tabpanel-complete" disabled={isDisabled('tab2')}/>
                                        <Tab id="order-tab-completed" label="Order Completed"
                                             aria-controls="order-tabpanel-completed" disabled={isDisabled('tab3')}/>
                                    </Tabs>
                                </AppBar>

                                {/* Tab Content 0: Start Order */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 0}
                                    id="order-tabpanel-start"
                                    aria-labelledby="order-tab-start"
                                    className="tab-panel form-order"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={(!orderStartedState)
                                            ? `Start an Order by selecting a Client.`
                                            : `To select a different Client, please create a different Order.`
                                        }
                                    />

                                    <Field name="OrgField">
                                        {props => (
                                            <SelectFromListInput
                                                source="org_id_select"
                                                displayField={(tabState === 0)}
                                                resourceName="Organization"
                                                listLabel="Select a Client"
                                                itemPlural="Clients"
                                                listFilters={{
                                                    active: true
                                                }}
                                                className="input-block input-400"
                                                isRequired={true}
                                                hasClear={false}
                                                onChangeFunc={(e) => handleSetValueFromSelect(e, 'org_id_select', form)}
                                            />
                                        )}
                                    </Field>
                                    <TextInput label="" source="org_id" className="hidden" disabled/>

                                    {startedMessageState.message &&
                                        <FieldMessage
                                            error={(startedMessageState.error)}
                                            text={startedMessageState.message}
                                        />
                                    }

                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Once Client is set, click Start Order. You will then be 
                                        able to add Products to the Order.`}
                                    />
                                    <div className="btns-container right">
                                        {!orderStartedState &&
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleFormStep(form, 'startOrder', values)}
                                                disabled={!!(isDisabled('submit0'))}
                                            >
                                                Start Order
                                            </Button>
                                        }
                                        {orderStartedState &&
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleFormStep(form, 'updateOrder', values)}
                                                disabled={!!(isDisabled('submit0'))}
                                            >
                                                Update Order
                                            </Button>
                                        }
                                    </div>
                                </div>

                                {/* Tab Content 1: Add Product Items */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 1}
                                    id="order-tabpanel-add"
                                    aria-labelledby="order-tab-add"
                                    className="tab-panel form-order"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Choose Products to add to this Order.`}
                                    />
                                    <FieldDescription
                                        marginBottom={false}
                                        text={`For each Product to add, click the Add icon. Enter a requested 
                                        quantity (up to available inventory) and check the Add checkbox.`}
                                    />
                                    <FieldDescription
                                        marginBottom={false}
                                        text={`To remove a Product from the list, click the Add icon and reduce 
                                        its requested quantity to 0.`}
                                    />
                                    <FieldDescription
                                        marginBottom={true}
                                        text={`Select a Title or Categorys to view available Products.`}
                                    />

                                    <OrderItemsAvailableListContainer
                                        {...props}
                                        displayFields={(tabState === 1)}
                                        orgId={values.org_id}
                                        productToUpdate={productToUpdate}
                                        updateList={updateProducts}
                                        onProductAdjust={(e) => handleAddOrRemoveItem(e, form, values)}
                                    />
                                    {productMessageState.message &&
                                        <FieldMessage
                                            error={(productMessageState.error)}
                                            text={productMessageState.message}
                                        />
                                    }
                                    <TextInput label="" source="order_items_filter_show_id"
                                               className="hidden" disabled
                                    />
                                    <TextInput label="" source="filter_product_type_id"
                                               className="hidden" disabled
                                    />
                                    <FieldDivider type="divider" margin="bottom"/>
                                    <FieldDescription text={`Items Added`} instructions={true}/>
                                    {(!values.items || values?.items?.length === 0) &&
                                        <FieldDescription
                                            marginBottom={true}
                                            text={`No Products have yet been added to this Order.`}
                                        />
                                    }
                                    {values?.items?.length > 0 &&
                                        <FieldDescription
                                            marginBottom={true}
                                            text={`The following Products will be added to this Order. You may adjust or remove Products after adding:`}
                                        />
                                    }
                                    <OrderItemsList
                                        itemList={values.items}
                                        hasEditQty={true}
                                        hasRemove={true}
                                        onEditQtyFunc={(e) => handleAddOrRemoveItem(e, form, values)}
                                        onRemoveFunc={(e) => handleAddOrRemoveItem(e, form, values)}
                                    />
                                    <FieldDivider type="divider" margin="bottom"/>

                                    <FieldDescription
                                        instructions={true}
                                        text={`When finished adding Products, click Continue to add a Due Date and 
                                        complete the Order.`}
                                    />
                                    <div className="order-warning">
                                        Note: Do not navigate away from this page or click the Back button in your
                                        browser, or your Order details may be lost.
                                    </div>
                                    <div className="btns-container right">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleFormStep(form, 'goToCreateOrder', values)}
                                            disabled={!!(isDisabled('submit1'))}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>

                                {/* Tab Content 2: Complete Order */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 2}
                                    id="order-tabpanel-complete"
                                    aria-labelledby="order-tab-complete"
                                    className="tab-panel form-order"
                                >
                                    <div className="btns-container margin-bottom">
                                        <Button
                                            color="primary" variant="outlined"
                                            onClick={() => handleFormStep(form, 'goToAddProducts', values)}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Select a Destination for this Order and enter the date this Order 
                                        should be received.`}
                                    />

                                    <Field name="shipToField">
                                        {props => (
                                            <SelectFromListInput
                                                source="ship_to_id_select"
                                                displayField={(tabState === 0)}
                                                resourceName="Contact"
                                                listLabel="Select a Destination"
                                                itemPlural="Contacts"
                                                listFilters={{
                                                    active: true,
                                                    params: {
                                                        filter: {
                                                            filter_org_id: values.org_id
                                                        },
                                                    }
                                                }}
                                                className="input-inline input-400"
                                                isRequired={true}
                                                refresh={refreshContacts}
                                                selectedObject={(values.ship_to_id && values.ship_to_name) ? {
                                                    id: values.ship_to_id,
                                                    name: values.ship_to_name
                                                } : {id: '', name: ''}}
                                                onChangeFunc={(e) => handleSetValueFromSelect(e, 'ship_to_id_select', form)}
                                                disabled={(!values.org_id)}
                                            />
                                        )}
                                    </Field>
                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={() => setCreateContactDialogOpen(true)}
                                        className={classes.addNewButton}
                                    >
                                        Create New Contact
                                    </Button>
                                    <TextInput label="" source="ship_to_id" className="hidden" disabled/>
                                    <TextInput label="" source="ship_to_name" className="hidden" disabled/>
                                    <br/>
                                    <FieldDivider type="break" margin="bottom"/>
                                    {values.org_id && values.ship_to_id &&
                                        <ContactDetailsFromID org_id={values.org_id} id={values.ship_to_id}/>
                                    }
                                    <FieldDivider type="break" margin="bottom"/>

                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Add a Shipping Carrier and method.`}
                                    />
                                    <div className="order-warning">
                                        Note: Please choose only UPS, FedEx, or Parrot Communications. Other methods
                                        are for internal use only.
                                    </div>
                                    <GetListSelectInput
                                        {...props}
                                        displayField={(tabState === 2)}
                                        source="shipped_with_select"
                                        label="Carrier *"
                                        displayType="name"
                                        resourceFile="default"
                                        resourceName="ShippingCompany"
                                        resourceActive={true}
                                        addLabel={false}
                                        onChangeFunc={(e) => handleSetValueFromSelect(e, 'shipped_with_select', form)}
                                        isRequired={true}
                                        disabled={(!values.org_id)}
                                        className="input-inline input-320"
                                        allowEmpty
                                    />
                                    <GetListSelectInput
                                        {...props}
                                        displayField={(tabState === 2)}
                                        source="shipping_type_select"
                                        label="Shipping Method *"
                                        displayType="name"
                                        resourceFile="default"
                                        resourceName="ShippingMethod"
                                        resourceActive={true}
                                        requiresFilter={true}
                                        filter={(values.shipped_with) ? {filter_company_id: values.shipped_with} : null}
                                        onChangeFunc={(e) => handleSetValueFromSelect(e, 'shipping_type_select', form)}
                                        className="input-inline input-280"
                                        disabled={(!values.shipped_with)}
                                        addLabel={false}
                                        isRequired={true}
                                        allowEmpty
                                    />
                                    <TextInput label="" source="shipped_with"
                                               className="hidden" disabled
                                    />
                                    <TextInput label="" source="shipping_type"
                                               className="hidden" disabled
                                    />
                                    <FieldDivider type="break"/>

                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`If there are additional instructions, add optional Notes.`}
                                    />
                                    <TextInput label="Notes" source="client_notes" multiline fullWidth={true}/>

                                    {values && values.items && values.items.length > 0 &&
                                        <>
                                            <FieldDivider type="divider" margin="bottom"/>
                                            <FieldDescription text={`Items Added`} instructions={true}/>
                                            <FieldDescription
                                                marginBottom={true}
                                                text={`The following Products were added to this Order. To edit or remove Products, click Back to the Add Products tab.`}
                                            />
                                            <OrderItemsList
                                                itemList={values.items}
                                                hasEditQty={false}
                                                hasRemove={false}
                                            />
                                            <FieldDivider type="divider" margin="bottom"/>
                                        </>
                                    }

                                    {orderState.message &&
                                        <FieldMessage error={(orderState.error)} text={orderState.message}/>
                                    }

                                    <FieldDescription
                                        instructions={true}
                                        text={`Click Complete Order to submit the Order and its added Items.`}
                                    />
                                    <div className="order-warning">
                                        Note: Do not navigate away from this page or click the Back button in your
                                        browser, or your Order details may be lost.
                                    </div>
                                    <div className="btns-container right">
                                        <Button
                                            color="primary" variant="outlined"
                                            onClick={() => handleFormStep(form, 'goToAddProducts', values)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleFormStep(form, 'createOrder', values)}
                                            disabled={!!(isDisabled('submit2'))}
                                        >
                                            Complete Order
                                        </Button>
                                    </div>
                                </div>

                                {/* Tab Content 4: Order Completed */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 3}
                                    id="order-tabpanel-completed"
                                    aria-labelledby="order-tab-completed"
                                    className="tab-panel form-order"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`This Order has been submitted. You may view this Order's status from 
                                        the Orders list.`}
                                    />
                                    {orderState.message &&
                                        <FieldMessage text={orderState.message}/>
                                    }
                                    <Button
                                        variant="outlined"
                                        component={Link}
                                        to={`/Order${defaultOrderQueryString}`}
                                    >
                                        View Orders List
                                    </Button>
                                </div>

                                <div className="order-disclaimer">
                                    Orders created after 12pm PST may not be fulfilled until the next business day.
                                </div>

                            </div>

                            <ContactCreateDialog
                                selectedOrgId={values.org_id}
                                openDialog={createContactDialogOpen}
                                onCompleteFunc={(e) => handleContactCreated(e, form, values)}
                                onCloseFunc={handleCloseCreateContactDialog}
                            />
                        </form>
                    )
                }}
            >
            </Form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withRouter(connect(
        mapStateToProps,
        {}
    )(OrderCreateForm)
);
