import PTheme from './theme';
import {makeStyles} from '@material-ui/core/styles';

export const useDialogStyles = makeStyles({
    root: {
        zIndex: '2 !important'
    },
    title: {
        margin: 0,
        padding: 12,
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 8,
        color: '#666666',
    }
});

export const useLoadingStyles = makeStyles({
    root: {
        display: 'inline-block',
        fontWeight: 500,
        margin: '0 0.5em',
        verticalAlign: 'bottom'
    },
    text: {
        display: 'inline-block',
        fontSize: 16,
        fontWeight: 500,
        letterSpacing: '0.015em',
        margin: '5px 0.5em 0 0.5em',
        verticalAlign: 'middle'
    },
    textError: {
        display: 'inline-block',
        fontSize: 16,
        fontWeight: 500,
        color: PTheme.palette.errorColor,
        letterSpacing: '0.015em',
        margin: '5px 0.5em 0 0.5em',
        verticalAlign: 'middle'
    }
});