import {buildOrderListQuery, buildSearchOrderQuery, setDateRangeValues} from './utils/dataFunctions';
import {ORDER_QUERY_DEFAULTS} from './global/orderReceiptDefaults';

/**
 * Product Order Resources for API
 *      Order:
 *          Get List by Filter
 *          Get One
 *          Update
 *      Order: Start (initialize)
 *      Order: Item(s)
 *          Get List by Order
 *          Get One
 *          Add One
 *          Update Qty
 *      Order: Create (finish)
 *      Orders by Organization
 *      Orders by Status
 */

export const RESOURCES = {
    'Order': {
        GET_LIST: function (params) {
            const filter = params.params.filter;
            const filterBy = params.params.filter.filter_by;
            const dateRange = setDateRangeValues(filter.filter_from, filter.filter_to);
            const pagination = params.params.pagination;
            const sort = params.params.sort;
            const queryObj = {
                page: pagination?.page ?? ORDER_QUERY_DEFAULTS.page,
                per_page: pagination?.perPage ?? ORDER_QUERY_DEFAULTS.per_page,
                sort_by: sort?.field ?? ORDER_QUERY_DEFAULTS.sort_by,
                sort_order: sort?.order ?? ORDER_QUERY_DEFAULTS.sort_order,
                filter: filter
            };
            let query = buildOrderListQuery(queryObj, false, true);
            let uri;

            if (filterBy === 'contact' && filter.filter_contact_id && dateRange.fromWithTime && dateRange.toWithTime) {
                // Get Orders by Contact ID (requires Client and Date Range)
                uri = '/api/v1/mgmt/orders/contact/' + filter.filter_contact_id + '/' + dateRange.fromWithTime + '/' + dateRange.toWithTime + query;

            } else if (
                (filterBy === 'contact' && filter.filter_org_id && !filter.filter_contact_id) ||
                (filterBy === 'client' && filter.filter_org_id)
            ) {
                // Get Orders by Organization ID (if by Client or by Client + Contact; includes Date Range)
                uri = '/api/v1/mgmt/orders/allByOrg/' + filter.filter_org_id + '/' + dateRange.fromWithTime + '/' + dateRange.toWithTime + query;

            } else if (filterBy === 'bill_ref' && filter.filter_bill_ref_id) {
                // Get Orders by selected Billing Reference ID
                uri = '/api/v1/mgmt/orders/billRef/' + filter.filter_bill_ref_id + query;

            } else if (filterBy === 'date_range' && dateRange.fromWithTime && dateRange.toWithTime) {
                // Get all Orders by Date Range
                queryObj.from = dateRange.fromWithTime;
                queryObj.to = dateRange.toWithTime;
                query = buildOrderListQuery(queryObj, true, true);
                uri = '/api/v1/mgmt/orders/all' + query;

            } else if (filterBy === 'id' && filter.filter_id) {
                // Get Orders by selected Order ID
                query = buildSearchOrderQuery(queryObj);
                uri = '/api/v1/mgmt/orders/all' + query;

            } else if (filterBy === 'sku' && filter.filter_sku) {
                // Get Orders by selected Product SKU
                uri = '/api/v1/mgmt/orders/sku/' + filter.filter_sku + query;

            } else if (filterBy === 'status' && filter.filter_status_id && dateRange.fromWithTime && dateRange.toWithTime) {
                // Get Orders by selected Status ID and Date Range
                uri = '/api/v1/mgmt/orders/status/' + filter.filter_status_id + '/' + dateRange.fromWithTime + '/' + dateRange.toWithTime + query;
            }
            return {
                'method': 'GET',
                'title': 'Orders',
                'uri': localStorage.getItem('server') + uri
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/order/' + params.id
            }
        }
    },
    'OrderStart': {
        CREATE: function (params) {
            let id = params?.data?.org_id ?? null;
            return {
                'method': 'PUT',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/order/start/' + id
            }
        }
    },
    'OrderItem': {
        GET_LIST: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Order Items',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/orderItems/' + id
            }
        },
        GET_ONE: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/orderItem/' + id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/orderItem/add'
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/orderItem/update'
            }
        }
    },
    'OrderCreate': {
        CREATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/order/create'
            }
        }
    },
    'OrdersByStatus': {
        GET_LIST: function (params) {
            let status = (params && params.status) ? params.status : null;
            const dateRange = setDateRangeValues(params.from, params.to);
            const queryObj = {
                page: 1,
                per_page: 10000,
                sort_by: ORDER_QUERY_DEFAULTS.sort_by,
                sort_order: ORDER_QUERY_DEFAULTS.sort_order
            };
            let query = buildOrderListQuery(queryObj, false, false);
            return {
                'method': 'GET',
                'title': 'Orders',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/orders/status/' + status + '/' + dateRange.fromWithTime + '/' + dateRange.toWithTime + query
            }
        }
    },
};

export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "OrderStart"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}
