import * as React from 'react';

/**
 * Assemble an object with ID and display label for selection tables
 *
 * @param resource
 * @param item
 * @returns {{name: string, id: string}}
 */
export const assembleListObject = (resource, item) => {
    let obj = {id: '', name: ''};
    let firstLast = '';
    // Populate ID and display label depending on Resource
    switch (resource) {
        case 'Contact':
            obj.id = item.id;
            obj.name = item.name;
            firstLast = item.first_name + ' ' + item.last_name;
            if (item.name !== firstLast) {
                if (item.name !== '') {
                    obj.name += ' - ';
                }
                obj.name += firstLast;
            }
            break;
        default:
            obj.id = item?.id ?? '';
            obj.name = item?.name ?? '';
            break;
    }
    return obj;
};

/* Column arrays for Lists and tables */

export const CLIENT_COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.name) ? row.original.name : ''}</span>
    }
];

export const CONTACT_COLUMNS = [
    {
        Header: 'Company',
        accessor: 'name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.name) ? row.original.name : ''}</span>
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.first_name) ? row.original.first_name : ''}</span>
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.last_name) ? row.original.last_name : ''}</span>
    },
    {
        Header: 'Address',
        accessor: 'address',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.address) ? row.original.address : ''}</span>
    },
    {
        Header: 'Address 2',
        accessor: 'address2',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.address2) ? row.original.address2 : ''}</span>
    },
    {
        Header: 'City',
        accessor: 'city',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.city) ? row.original.city : ''}</span>
    },
    {
        Header: 'State',
        accessor: 'state',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.state) ? row.original.state : ''}</span>
    },
    {
        Header: 'ZIP',
        accessor: 'zip',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.zip) ? row.original.zip : ''}</span>
    },
    {
        Header: 'Country',
        accessor: 'country',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.country) ? row.original.country : ''}</span>
    }
];

export const PRODUCT_TYPE_COLUMNS = [
    {
        Header: 'Parent Category',
        accessor: 'parent_type_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.parent_type_name) ? row.original.parent_type_name : ''}</span>
    },
    {
        Header: 'Category',
        accessor: 'name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.name) ? row.original.name : ''}</span>
    },
    {Header: 'Description', accessor: 'description', sortType: 'basic'}
];

export const SHIPPING_COMPANY_COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
        disableFilters: true,
        Cell: ({row}) => <span>{row.original.name}</span>
    }
];

export const SHIPPING_METHOD_COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
        disableFilters: true,
        Cell: ({row}) => <span>{row.original.name}</span>
    }
];

export const TITLE_COLUMNS = [
    {
        Header: 'Client',
        accessor: 'org_name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.org_name) ? row.original.org_name : ''}</span>
    },
    {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.name) ? row.original.name : ''}</span>
    }
];

export const TITLE_BY_ORG_COLUMNS = [
    {
        Header: 'Name',
        accessor: 'name',
        sortType: 'basic',
        Cell: ({row}) => <span>{(row.original.name) ? row.original.name : ''}</span>
    }
];
