import * as React from "react";
import {
    Link,
    Redirect,
    withRouter
} from 'react-router-dom';
import {connect} from 'react-redux';
import {Title} from 'react-admin';
import {checkAuthentication} from '../utils/authFunctions';
import {assembleDefaultQueryString} from '../utils/dataFunctions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

const Dashboard = ({keycloakReady, keycloakAuthenticated, tokens, location}) => {

    let isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);

    // If route has a query string, redirect
    if (isAuthenticated && location.pathname !== '/') {
        return <Redirect to="/"/>
    }

    const defaultOrderQueryString = assembleDefaultQueryString('Order');

    return (
        <>
            {keycloakReady && isAuthenticated &&
            <>
                <div className="list-card-container">
                    <Card className="list-card">
                        <Title title="Dashboard"/>
                        <CardHeader title="Welcome to the Parrot Communications Inventory"/>
                        <CardContent>
                            <p>Add and manage your Titles and Contacts, and create Orders.</p>
                        </CardContent>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Products"/>
                        <CardContent>
                            <p>View your Products.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Product">
                                Browse Products
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Orders"/>
                        <CardContent>
                            <p>View and create Orders.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to={`/Order${defaultOrderQueryString}`}>
                                Browse Orders
                            </Button>
                            <Button variant="outlined" component={Link} to="/Order/create">
                                Add an Order
                            </Button>
                        </CardActions>
                    </Card>
                </div>

                <div className="list-card-container">
                    <Card className="list-card half">
                        <CardHeader title="Titles"/>
                        <CardContent>
                            <p>View Titles (Shows, Events, Properties, other entities).</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Show">
                                Browse your Titles
                            </Button>
                        </CardActions>
                    </Card>

                    <Card className="list-card half last">
                        <CardHeader title="Contacts"/>
                        <CardContent>
                            <p>View, create and manage Client Contacts.</p>
                        </CardContent>
                        <CardActions>
                            <Button variant="outlined" component={Link} to="/Contact">
                                Browse Contacts
                            </Button>
                            <Button variant="outlined" component={Link} to="/Contact/create">
                                Add a Contact
                            </Button>
                        </CardActions>
                    </Card>
                </div>
            </>
            }
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        {}
    )(Dashboard)
);
