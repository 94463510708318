import React from 'react';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';

const usePromptStyles = makeStyles({
    root: {
        padding: '12px 1em 10px 1em'
    }
});

const promptContentStyles = {
    display: 'block',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '0.015em',
    margin: 0
};

const ListMessage = ({text}) => {

    const classes = usePromptStyles();

    return (
        <Box textAlign="left" m={1} classes={classes}>
            <p style={promptContentStyles}>
                {text}
            </p>
        </Box>
    )
};

export default ListMessage;
