import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders} from '../utils/headers';
import OrderItemsAvailableList from './OrderItemsAvailableList';
import {TextInput} from 'react-admin';
import OrgShowSelectFromListInput from "./OrgShowSelectFromListInput";
import SelectFromListInput from "./SelectFromListInput";
import FieldDivider from "./FieldDivider";

/**
 * A component for retrieving and displaying a Product List by Order ID
 *
 * @param orderId
 * @param orgId
 * @param displayFields
 * @param onProductAdjust
 * @param productToUpdate
 * @param refreshList
 * @param updateList
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OrderItemsAvailableListContainer = (
    {
        orderId,
        orgId,
        displayFields,
        onProductAdjust,
        productToUpdate,
        refreshList = false,
        updateList = false,
        ...props
    }) => {

    const [currentOrgId, setCurrentOrgId] = useState(null);
    const [itemList, setItemList] = useState({
        list: []
    });
    const [filterName, setFilterName] = useState(null);
    const [filterNameValue, setFilterNameValue] = useState(null);
    const [filterSKU, setFilterSKU] = useState(null);
    const [filterSKUValue, setFilterSKUValue] = useState(null);
    const [filterTitleId, setFilterTitleId] = useState(null);
    const [filterTypeId, setFilterTypeId] = useState(null);
    const [updateComplete, setUpdateComplete] = useState(false);
    const [updateProductComplete, setUpdateProductComplete] = useState(false);

    // Callback: Retrieve list of Products
    const getItemList = useCallback(() => {
        return JSON.parse(JSON.stringify(itemList.list));
    }, [itemList]);

    // Effect: Fetch all Products by Org ID
    useEffect(
        () => {
            if (orgId && !updateComplete && displayFields) {
                setCurrentOrgId(orgId);
                const fetchItems = () => {
                    const params = {
                        active: true,
                        id: orgId
                    };

                    const cfg = resources.ProductsByOrg.GET_LIST(params);
                    axios({
                        method: 'GET',
                        url: cfg.uri,
                        headers: getGetHeaders(),
                        maxBodyLength: Infinity,
                        maxContentLength: Infinity
                    }).then((response) => {
                        if (response.data && response.data.length > 0) {
                            processItems(response.data);
                        }
                        // resolve(response);
                    }).catch((error) => {
                        console.log('error response for Products: ', error);
                        if (error.response) {
                            // Server response outside 2xx
                        } else if (error.request) {
                            // No response
                        }
                    });
                };

                const processItems = (data) => {
                    let list = [];
                    if (data && data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            const item = data[i];
                            // Portal excludes 0-qty Products
                            if (item.qty > 0) {
                                const itemToAdd = {
                                    active: item.active,
                                    added: false,
                                    id: item.id,
                                    org_id: item.org_id,
                                    org_name: item.org_name,
                                    qty: item.qty,
                                    qty_available: (item.qty >= 0) ? item.qty : 0,
                                    qty_requested: 0,
                                    show_id: item.show_id,
                                    show_name: item.show_name,
                                    sku: item.sku,
                                    thumbnail: item.thumbnail,
                                    title: item.title,
                                    type_id: item.type_id,
                                    type_name: item.type_name
                                };
                                list.push(itemToAdd);
                            }
                        }
                    }
                    // Store a complete list of Products
                    setItemList({list: list});
                    setUpdateComplete(true);
                };

                // If an Org ID becomes available or has changed, or if a refresh is needed, fetch Products
                if (orgId || !updateComplete) {
                    fetchItems();
                }
            }

        }, [orgId, displayFields, updateComplete]
    );

    // Effect: Update a Product's qty edited outside this component
    useEffect(() => {
        const updateProductQty = () => {
            let list = getItemList();
            const idx = list.findIndex(r => r.id === productToUpdate.id);
            if (idx !== -1) {
                list[idx].qty_requested = productToUpdate.qty_requested;
                list[idx].added = (list[idx].qty_requested !== 0);
                setItemList({list: list});
            }
            setUpdateProductComplete(true);
        };
        if (!updateProductComplete) {
            updateProductQty();
        }
    }, [getItemList, productToUpdate, updateProductComplete]);

    // Effect: Update if another component has edited a requested Product Qty
    useEffect(() => {
        if (updateList && productToUpdate) {
            setUpdateProductComplete(false);
        }
    }, [productToUpdate, updateList]);

    // Effect: Refresh if Org ID is changed or list should refresh
    useEffect(
        () => {
            if ((orgId !== currentOrgId) || refreshList) {
                setUpdateComplete(false);
            }

        }, [orgId, currentOrgId, refreshList, updateComplete]
    );

    // Effect: Debounce SKU input
    useEffect(() => {
        const updateFilterSKU = setTimeout(() => {
            setFilterSKUValue(filterSKU);
        }, 1000);

        return () => clearTimeout(updateFilterSKU);

    }, [filterSKU]);

    // Effect: Debounce Title input
    useEffect(() => {
        const updateFilterName = setTimeout(() => {
            setFilterNameValue(filterName);
        }, 1000);

        return () => clearTimeout(updateFilterName);

    }, [filterName]);

    const blurFilter = () => {
        const active = document.activeElement;
        if (active) {
            active.blur();
        }
    };

    const handleSearchNameSKUFilter = (type, e) => {
        let val = e?.currentTarget?.value ?? null;
        if (type === 'name') {
            setFilterName(val);
        }
        if (type === 'sku') {
            setFilterSKU(val);
        }
    };

    const handleSelectTitleFilter = (e) => {
        let id = (e) ? e : null;
        setFilterTitleId(id);
        blurFilter();
    };

    const handleSelectTypeFilter = (e) => {
        let id = (e) ? e : null;
        setFilterTypeId(id);
        blurFilter();
    };

    /**
     * Handle add/remove/update event from Available List
     *
     * @param e
     */
    const handleAddChange = (e) => {
        let list = JSON.parse(JSON.stringify(itemList.list));
        const idx = list.findIndex(r => r.id === e.item.id);
        if (idx !== -1) {
            // Update the Product's requested Qty
            list[idx].qty_requested = parseInt(e.qty_requested, 10);

            // If the Product is to be added or removed, or the quantity is 0, update the row
            list[idx].added = (list[idx].qty_requested !== 0);

            // Update this available list
            setItemList({list: list});

            // Notify the form that this item is to be added or removed on this Order
            const item = JSON.parse(JSON.stringify(list[idx]));
            item.product_id = item.id;
            item.qty_requested = list[idx].qty_requested;
            item.submitted = false;
            if (typeof onProductAdjust === 'function') {
                onProductAdjust({item: item, add: item.added});
            }
        }
    };

    return (
        <>
            <div className="order-items-filter">
                <OrgShowSelectFromListInput
                    selectOrg={!orgId}
                    selectedOrgId={orgId}
                    orgLabel="Select a Client"
                    orgSrc="order_items_filter_org_id"
                    showLabel="Filter Products by Title"
                    showSrc="order_items_filter_show_id"
                    addLabel={false}
                    inline={true}
                    className="input-inline third"
                    onSelectShow={(e) => handleSelectTitleFilter(e)}
                />
                <SelectFromListInput
                    inAdminForm={true}
                    source="filter_product_type_id"
                    resourceName="ProductType"
                    listLabel="Filter Products by Category"
                    itemPlural="ProductType"
                    listFilters={{
                        active: true
                    }}
                    className="input-inline third"
                    isRequired={true}
                    returnType="string"
                    onChangeFunc={(e) => handleSelectTypeFilter(e)}
                />
                <FieldDivider type="break" margin="bottom"/>
                <TextInput
                    key="filter_product_sku"
                    label="Search Product SKU"
                    source="filter_product_sku"
                    placeholder="SKU-N00012345"
                    onChange={(e) => handleSearchNameSKUFilter('sku', e)}
                    className="input-inline third"
                />
                <TextInput
                    key="filter_product_title"
                    label="Search Product Name"
                    source="filter_product_title"
                    alwaysOn
                    onChange={(e) => handleSearchNameSKUFilter('name', e)}
                    className="input-inline third"
                />
                <FieldDivider type="break"/>
            </div>
            <OrderItemsAvailableList
                fullItemList={itemList.list}
                filterName={filterNameValue}
                filterSKU={filterSKUValue}
                filterTitleId={filterTitleId}
                filterTypeId={filterTypeId}
                excludeZeroQty={false}
                onEditFunc={(e) => handleAddChange(e)}
            />
        </>
    );
}

export default OrderItemsAvailableListContainer;
