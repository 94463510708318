/**
 * Resources for API
 *      Contact
 *      Organization
 *      Product Category (Type)
 *      Shipping Company
 *      Shipping Method
 *      Show
 */

export const RESOURCES = {
    'Contact': {
        GET_LIST: function (params) {
            let org_id = (params && params.params.filter.filter_org_id) ? params.params.filter.filter_org_id : null;
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Contacts',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contacts/' + org_id + '/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contact/' + params.id
            };
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contact/create'
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/contact/update'
            };
        }
    },
    'Organization': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Clients',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organizations/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/' + params.id
            };
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/organization/update'
            };
        }
    },
    'ProductType': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Product Types',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/productTypes/' + active
            };
        }
    },
    'ShippingCompany': {
        GET_LIST: function (params) {
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Shipping Companies',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/companies/' + active
            };
        }
    },
    'ShippingMethod': {
        GET_LIST: function (params) {
            let company_id = (params && params.params.filter.filter_company_id) ? params.params.filter.filter_company_id : null;
            let active = (params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Shipping Methods',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shipping/types/' + company_id + '/' + active
            };
        }
    },
    'Show': {
        GET_LIST: function (params) {
            let active = (params && params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Titles',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/shows/' + active
            };
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/show/' + params.id
            };
        }
    },
    'ShowByOrg': {
        GET_LIST: function (params) {
            let org_id = (params && params.org_id) ? params.org_id : null;
            let active = (params && params.active) ? params.active : false;
            return {
                'method': 'GET',
                'title': 'Titles',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/all/shows/' + org_id + '/' + active
            };
        }
    }
};

export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "Organization"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    };
}
