import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {RESOURCES as resources} from '../resources';
import {postProcessData} from '../dataProviderPostProcess';
import {getGetHeaders} from '../utils/headers';
import axios from 'axios';

const styles = {
    container: {lineHeight: 1.4, margin: '0 0 4px 4px'},
    label: {fontStyle: 'italic'},
    name: {fontWeight: 600}
};

/**
 * A component for getting and displaying full Contact Details from an ID
 *
 * @param id
 * @param org_id
 * @return {JSX.Element}
 * @constructor
 */
const ContactDetailsFromID = ({id, org_id}) => {
    const [contact, setContact] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [loaded, setLoaded] = useState(false);

    // Callback: Get Contacts
    const getContacts = useCallback(() => {
        let cfg = resources.Contact.GET_LIST({
            active: true,
            params: {
                filter: {
                    filter_org_id: org_id
                },
            }
        });

        axios({
            method: cfg.method,
            url: cfg.uri,
            headers: getGetHeaders()
        })
            .then((response) => {
                if (response.data) {
                    // Post-process the data as if a List view
                    const rdata = postProcessData('Contact', 'GET_LIST', {}, response);
                    setContacts(rdata.data);
                    // Locate Contact by ID
                    const item = rdata.data.find(r => r.id === id);
                    if (item) {
                        setContact(item);
                    }
                }
            })
            .catch((err) => {
                console.log('Error retrieving Contacts');
                setContact(null);
            })
            .finally(() => {
                setLoaded(true);
            });
    }, [id, org_id]);

    // Effect: Get Contact list
    useEffect(
        () => {
            if (id && !loaded) {
                return getContacts();
            }

        }, [id, loaded, getContacts]
    );

    if (contacts.length === 0 || !contact) {
        return (
            <Fragment>
            </Fragment>
        );
    }

    return (
        <div style={styles.container}>
            <span style={styles.name}>{contact.name}</span><br/>
            {contact.first_name} {contact.last_name}<br/>
            {contact.address}<br/>
            {contact.address2}<br/>
            {contact.city}
            {contact.state && contact.state !== '' &&
                <Fragment>
                    , {contact.state}
                </Fragment>
            } {contact.zip}
            <br/>
            {contact.country && contact.country !== '' &&
                <Fragment>
                    {contact.country}
                    <br/>
                </Fragment>
            }
            {contact.email && contact.email !== '' &&
                <Fragment>
                    <span style={styles.label}>Email</span>: {contact.email}<br/>
                </Fragment>
            }
            {contact.phone && contact.phone &&
                <Fragment>
                    <span style={styles.label}>Phone</span>: {contact.phone}
                </Fragment>
            }
        </div>
    );
}

export default ContactDetailsFromID;
