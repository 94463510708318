import React from 'react';
import {Show as ReactAdminShow} from 'react-admin';
import ShowActions from './ShowActions';

const Show = ReactAdminShow;

Show.defaultProps = {
    actions: <ShowActions/>
};

export default Show;
