import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Field, useForm} from 'react-final-form';
import SelectFromListDialog from './SelectFromListDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {InputAdornment} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

/**
 * A component to display a field to launch a list selection component
 *
 * @param disabled
 * @param fieldValue
 * @param isRequired
 * @param resourceName
 * @param source
 * @param onChangeFunc
 * @param hasClear
 * @param error
 * @param errorText
 * @param inAdminForm
 * @param itemPlural
 * @param listFilters
 * @param listLabel
 * @param listSort
 * @param refresh
 * @param returnType
 * @param selectedObject
 * @param size
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const SelectFromListInput = (
    {
        disabled,
        fieldValue,
        isRequired,
        resourceName,
        source,
        onChangeFunc,
        hasClear = true,
        error = false,
        errorText = '',
        inAdminForm = false,
        itemPlural = 'Items',
        listFilters = {active: true},
        listLabel = '',
        listSort = {field: 'name', order: 'ASC'},
        refresh = false,
        returnType = 'object',
        selectedObject = {id: '', name: ''},
        size = 'small',
        className = "input-block input-400"
    }) => {

    const filterForm = useForm();
    const mounted = useRef(false);

    const [open, setOpen] = useState(false);
    const [valueObject, setValueObject] = useState(selectedObject);

    // Callback: Populate field with new item
    const selectNewItem = useCallback((item) => {
        if (mounted.current === true) {
            setValueObject({id: item.id, name: item.name});
            const returnValue = (returnType === 'string') ? item.id : item;
            if (inAdminForm) {
                filterForm.change(source, returnValue);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Effect: A new item has been passed to this component
    useEffect(() => {
        mounted.current = true;

        if (selectedObject.id !== '') {
            selectNewItem(selectedObject);
        }

        return () => {
            mounted.current = false;
        };
    }, [selectedObject, selectNewItem]);

    const handleClickToSelect = (e) => {
        setOpen(true);
    };

    const handleItemSelected = (e) => {
        setValueObject({id: e.id, name: e.name});
        const returnValue = (returnType === 'string') ? e.id : e;
        if (inAdminForm) {
            filterForm.change(source, returnValue);
        }
        if (typeof onChangeFunc === 'function') {
            onChangeFunc(returnValue);
        }
    };

    const handleClearSelected = (e) => {
        setValueObject({id: '', name: ''});
        if (inAdminForm) {
            filterForm.change(source, '');
        }
        if (typeof onChangeFunc === 'function') {
            onChangeFunc(null);
        }
        e.preventDefault();
        e.stopPropagation();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <span className={className}>
                <TextField
                    type="text"
                    variant="filled"
                    size={size}
                    id={`select-from-list-input-${resourceName}`}
                    label={listLabel}
                    value={valueObject.name}
                    onClick={(e) => (!disabled) ? handleClickToSelect(e) : undefined}
                    disabled={disabled}
                    required={isRequired}
                    fullWidth={true}
                    error={(error)}
                    helperText={(errorText !== '') ? errorText: undefined}
                    InputProps={{
                        endAdornment: (
                            (hasClear && valueObject.name) ?
                                <InputAdornment
                                    position="end"
                                    onClick={(e) => handleClearSelected(e)}
                                    style={{zIndex: 10, cursor: 'pointer', color: 'rgba(0,0,0,0.7)'}}
                                >
                                    <HighlightOffIcon/>
                                </InputAdornment>
                                : null
                        ),
                        // onClick: (e) => (!disabled) ? handleClickToSelect(e) : undefined
                    }}
                />
            </span>
            <SelectFromListDialog
                itemPlural={itemPlural}
                listFilters={listFilters}
                listLabel={listLabel}
                listSort={listSort}
                openDialog={open}
                resourceName={resourceName}
                refresh={refresh}
                onSelectFunc={(e) => handleItemSelected(e)}
                onCloseFunc={() => handleClose()}
            />
            {inAdminForm &&
                <Field name={source}>
                    {props => (
                        <TextField
                            id={source}
                            name={source}
                            label=""
                            value={valueObject.id}
                            className="input-hidden"
                            disabled={true}
                            error={(error)}
                        />
                    )}
                </Field>
            }
        </>
    );
}

export default SelectFromListInput;
