import * as React from "react";
import {AppBar} from 'react-admin';
import PTheme from '../global/theme';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    toolbar: {
        backgroundColor: PTheme.palette.primary1Color
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    spacer: {
        flex: 1
    },
});

const ParrotAppBar = props => {
    const classes = useStyles();
    return (
        <AppBar className={classes.toolbar} userMenu={<></>} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span className={classes.spacer}/>
        </AppBar>
    );
};

export default ParrotAppBar;
