import Config from '../config';

const tokensReducer = (state = Config.tokensDefault, action) => {
    if (action.type === 'SET_TOKENS') {
        return {
            ...state,
            token: action.tokens.token
        }
    }
    return state;
};

export default tokensReducer;